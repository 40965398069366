<template>
  <v-dialog v-model="isOpenDialog" :max-width="maxDialogWidth">
    <div class="d-flex loginContainer">
      <div v-if="$vuetify.breakpoint.width > 725" :style="{ backgroundImage: 'url(' + loginBG + ')' }"
           class="loginLeft d-none d-sm-block" style="background-size: cover;">
        <div class="d-flex align-center justify-center leftContent px-4 py-15"
             style="height: 100%; width: 100%; position: relative;">
          <div class="text-center">
            <img id="loginLeftBg" :src="logo2" alt="department logo" style="opacity: 0; width: 50%; height: auto;"
                 @load="opaImage">
            <br/>
            <span class="poms mt-3">
            POMS
          </span>
            <br/>
            <span class="white--text">
            Pollution Online Monitoring System
          </span>
            <br/>
            <span class="white--text" style="font-weight: 300">
            ระบบเผ้าระวังและเตือนภัยมลพิษระยะไกล
          </span>
          </div>
        </div>
        <div style="position: absolute; bottom: 4px; left: 4px; font-size: 11px; color: white;">
          version : {{ version }}
        </div>
      </div>
      <div class="loginRight white pa-4">
        <div style="position: relative;">
          <div style="position: absolute; top: 0; right: 0;">
            <v-icon class="primary--text" @click="isOpenDialog=false">mdi-close</v-icon>
          </div>
        </div>
        <div class="rightContent pa-1 pa-sm-3">
          <div style="width: 100%;">
            <v-text-field id="emc_username" v-model.trim="username" color="primary" placeholder="ชื่อผู้ใช้"
                          prepend-inner-icon="mdi-account-circle" style="" @keyup.enter="doLogin()"/>
            <v-text-field id="emc_password" v-model.trim="password" color="primary" placeholder="รหัสผ่าน"
                          prepend-inner-icon="mdi-key" style="" type="password" @keyup.enter="doLogin()"/>
            <v-radio-group v-model="mode">
              <v-radio :value="1" label="เจ้าหน้าที่กรมโรงงานอุตสาหกรรม"></v-radio>
              <v-radio :value="2" label="ผู้ประกอบการ"></v-radio>
              <div class="caption"
                   style="margin-left: 34px; font-family: inherit; color: #ff5000;">
                แจ้งปัญหาการใช้งานระบบ คลิก <a class="clickText primary--text" @click="openLink('https://poms.diw.go.th/factory-ws/node/2')">ที่นี่</a>
              </div>
            </v-radio-group>
            <v-btn block color="primary white--text" elevation="0"
                   @click="doLogin()">
              {{ buttonText }}
              <v-progress-circular v-show="isFetching" class="ml-3" indeterminate size="16"></v-progress-circular>
            </v-btn>
            <v-btn block class="mt-2" color="primary" elevation="0" outlined @click="openLink('http://authen.industry.go.th/')">
              เจ้าหน้าที่<span v-if="$vuetify.breakpoint.width > 345">สำนักงาน</span>อุตสาหกรรม<span
              v-if="$vuetify.breakpoint.width > 365">จังหวัด</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import loginBG from '../../assets/img/login.png'
import departmentLogo from '../../assets/img/department.png'
import utils from '../../assets/js/utils'
import { mapState } from 'vuex'

export default {
  name: 'LoginDialog',
  props: {
    isShow: Boolean
  },
  data () {
    return {
      version: process.env.VUE_APP_VERSION,
      loginBG: loginBG,
      departmentLogo: departmentLogo,
      username: '',
      password: '',
      loginFail: false,
      isFetching: false,
      buttonText: 'เข้าสู่ระบบ',
      isOpenDialog: false,
      mode: 1,
      logo2: require('../../assets/img/ic_logo_3.png')
    }
  },
  methods: {
    async doLogin () {
      if (this.username && this.password) {
        this.isFetching = true
        this.buttonText = 'กำลังเข้าสู่ระบบ'
        const payload = {
          username: this.username,
          password: this.password,
          mode: this.mode
        }
        const isSuccess = await this.$store.dispatch('user/doLogin', payload)
        if (isSuccess) {
          this.isOpenDialog = false
          this.isFetching = false
          this.buttonText = 'เข้าสู่ระบบ'
          this.username = ''
          this.password = ''
          this.$emit('successQuery', true)
          this.saveDeviceToBackend()
          return true
        } else {
          this.loginFail = true
          this.buttonText = 'เข้าสู่ระบบไม่สำเร็จ'
          this.isFetching = false
          return false
        }
      } else {
        this.buttonText = 'ต้องการชื่อผู้ใช้และรหัสผ่าน'
      }
    },
    opaImage () {
      const imgTag = document.getElementById('loginLeftBg')
      imgTag.style.opacity = '1'
    },
    saveDeviceToBackend () {
      const payload = {
        uuid: this.uuid || '',
        device: this.deviceId || '',
        token: this.firebaseCloudMessageToken || '',
        platform: utils.getDeviceType()
      }
      this.$store.dispatch('saveDeviceToBackend', payload)
    },
    openLink (href) {
      const deviceType = utils.getDeviceType()
      if (deviceType !== 3) {
        window.longdoJsInterface.openUrl({ url: href, title: 'เปิดด้วย' })
      } else {
        window.open(href, '_blank')
      }
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.data = newVal
    },
    isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.isOpenDialog = newVal
      }
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
    }
  },
  computed: {
    ...mapState('user', ['uuid']),
    ...mapState(['deviceId', 'firebaseCloudMessageToken']),
    maxDialogWidth () {
      const width = this.$vuetify.breakpoint.width
      if (width <= 725) {
        if (width <= 350) {
          return 270
        } else {
          return 350
        }
      } else {
        return 740
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 725px) {
  .loginRight {
    width: 100%;
  }
}

@media (min-width: 725px) {

  .loginLeft {
    width: 50%;
    position: relative;
  }

  .leftContent {
    background: rgba(0, 87, 113, 0.75);
  }

  .loginRight {
    width: 50%;
  }
}

.rightContent {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.poms {
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 64px;
  color: white;
}

::v-deep .v-input--selection-controls__input + .v-label {
  color: black !important;
  font-weight: 300 !important;
}

/* <editor-fold desc="custom v-text-field white bg"> */

::v-deep .v-text-field--outlined >>> fieldset {
  border-color: #005771;
}

::v-deep .theme--light.v-input input::placeholder {
  color: #005771;
  font-weight: 300;
}

::v-deep .theme--light.v-input input, .theme--light.v-input textarea {
  color: #005771;
}

::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #005771;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  padding-left: 4px;
}

::v-deep .v-input__prepend-inner > .v-input__icon--prepend-inner > .theme--light.v-icon {
  color: #005771;
}

::v-deep .theme--light.v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before {
  border-color: #005771;
}

/* </editor-fold> */
</style>
