import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

// https://stackoverflow.com/questions/63633055/utilizing-environment-variables-in-firebase-messaging-sw-js-in-react-boilerplate

const firebaseConfigDev = {
  apiKey: 'AIzaSyCUrkc6sqwGJoixWEsDcOipGj_SuhLkOIo',
  authDomain: 'diw-poms-dev.firebaseapp.com',
  projectId: 'diw-poms-dev',
  storageBucket: 'diw-poms-dev.appspot.com',
  messagingSenderId: '64452376504',
  appId: '1:64452376504:web:2fd0077a3bc3f3a375414d',
  measurementId: 'G-SPLT5W1J9Q'
}

const firebaseConfigProd = {
  apiKey: 'AIzaSyDP1fkGnEgAjpxwexVwxdyA4f7DJFoD-cI',
  authDomain: 'diw-poms-prod.firebaseapp.com',
  projectId: 'diw-poms-prod',
  storageBucket: 'diw-poms-prod.appspot.com',
  messagingSenderId: '130002518655',
  appId: '1:130002518655:web:6f3b80913dc8fbdbc3754a',
  measurementId: 'G-9ZS0KVZBJR'
}

const isProd = window.location.origin.includes('diw.go.th')

let messaging = null

try {
  firebase.initializeApp(isProd ? firebaseConfigProd : firebaseConfigDev)
  messaging = firebase.messaging()
} catch (e) {
  console.warn(e)
}

export default messaging
