import utils from '../../assets/js/utils'
import api from '../../assets/js/api'

const dashboard = {
  namespaced: true,
  state: {
    offlineFactoryKey: 'dataDashboardFactory',
    factoryHasNextPage: true,
    allFactoryData: [],
    nextFactoryPage: 1,
    hasBeenFetchFactoryInOffline: false,

    lat: 13.721867, // Metamedia location
    lon: 100.530319 // Metamedia location
  },
  getters: {},
  actions: {
    async clearState ({ state }) {
      const original = {
        offlineFactoryKey: 'dataDashboardFactory',
        factoryHasNextPage: true,
        allFactoryData: [],
        nextFactoryPage: 1,
        hasBeenFetchFactoryInOffline: false,

        lat: 13.721867, // Metamedia location
        lon: 100.530319 // Metamedia location
      }
      for (const key of Object.keys(original)) {
        state[key] = original[key]
      }
    },
    async fetchFactoryList ({ commit, state, dispatch }, payload) {
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/factory-list?'
      let response
      try {
        response = await api.post({ url: url, data: formData })
      } catch (e) {
        state.factoryHasNextPage = false
        console.log(e)
        return []
      }
      state.nextFactoryPage += 1
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.factoryHasNextPage = data.data.hasNextPage
        let items = data.data.items
        if (!items || items.length <= 0) {
          state.factoryHasNextPage = false
        }
        items = items.map(
          item => {
            const ret = { ...item }
            const geom = String(item.geom).replace('POINT', '').replace('(', '').replace(')', '')
            const lonlat = geom.split(' ')
            ret.lon = parseFloat(lonlat[0])
            ret.lat = parseFloat(lonlat[1])
            return ret
          }
        )
        return items
      } else {
        state.factoryHasNextPage = false
        console.warn(data)
        return []
      }
    },
    async getFactoryList ({ commit, state, dispatch }, payload) {
      // pre-process query
      if (!payload.lat) payload.lat = state.lat
      if (!payload.lon) payload.lon = state.lon
      if (!payload.page) payload.page = state.nextFactoryPage
      if (window.longdoJsInterface.Util.isOnLine() === true) {
        if (state.factoryHasNextPage) {
          const newFactoryData = await dispatch('fetchFactoryList', payload) // fetch new data
          state.allFactoryData = [...state.allFactoryData, ...newFactoryData]
          dispatch('saveFactoryList')
          return newFactoryData
        } else {
          console.warn('no more page')
          return []
        }
      } else {
        if (!state.hasBeenFetchFactoryInOffline && !payload.ignore_visible) { // if offline and not fetch
          state.hasBeenFetchFactoryInOffline = true
          state.factoryHasNextPage = false
          let newData = []
          try {
            const result = await window.longdoJsInterface.getStorage({
              key: state.offlineFactoryKey
            })
            newData = JSON.parse(result)
          } catch (error) {
            console.warn(error)
          }
          for (const item of newData) {
            item.logo = item.offlineLogo || item.logo
          }
          state.allFactoryData = [...newData]
          return newData
        } else { // if offline and has been fetch
          return []
        }
      }
    },
    async saveFactoryList ({ commit, state, dispatch }, payload) {
      const data = state.allFactoryData
      for (const item of data) {
        let isCacheSuccess = false
        try {
          // save image to phone storage
          isCacheSuccess = await window.longdoJsInterface.addCache({
            cacheName: state.offlineFactoryKey,
            url: item.logo
          })
        } catch (error) {
          if (error.code !== 1) {
            console.warn('saveFactoryList :: addCache -->', error)
          }
        }
        if (isCacheSuccess) {
          try {
            // load image path from phone storage
            item.offlineLogo = await window.longdoJsInterface.getCache({
              cacheName: state.offlineFactoryKey,
              url: item.logo,
              returnType: 'stringUrl' // return file path
            })
          } catch (error) {
            if (error.code !== 1) {
              console.warn('saveCompanyList :: getCache -->', error)
            }
          }
        } else {
          // console.warn('saveFactoryList :: can not addCache')
        }
      }
      window.longdoJsInterface.addStorage({
        key: state.offlineFactoryKey,
        value: JSON.stringify(data)
      }).then(
        () => {},
        (error) => {
          if (error.code !== 1) {
            console.warn('saveCompanyList :: addStorage -->', error)
          }
        }
      )
    },
    async setBookmarkFactory ({ commit, state, dispatch }, payload) {
      if (!payload.factoryId || !payload.device || !payload.token || !payload.platform) {
        if (String(payload.is_bookmarked) === '0' || String(payload.is_bookmarked) === '1') {
        } else {
          console.warn('require key [factoryId, device, token, platform, is_bookmarked] get', payload)
          return false
        }
      }
      const id = payload.factoryId
      delete payload.factoryId
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/set/bookmark/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data

      return data.code === 'SUCCESS'
    }
  },
  mutations: {}
}

export default dashboard
