<template>
  <div class="pt-3 pr-3 notification_parent" style="position: absolute; top: 0; right: 0;">
    <div v-for="(item, index) of items" :key="index">
      <transition name="item">
        <div v-show="item.isShow" class="elevation-2 d-flex flex-row primary lighten-2 pa-3 mb-2"
             style="width: 300px; position:relative;">
          <div class="flex-grow-0 flex-shrink-1">
            <v-icon class="white--text" large>mdi-information-outline</v-icon>
          </div>
          <div class="flex-grow-1 flex-shrink-0 pl-2 pr-1" style="width: calc(300px - 36px - 25px);">
            <span class="white--text">{{ item.title }}</span>
            <div style="font-size: 12px;">
              <span class="white--text">{{ JSON.stringify(item.body) }}</span>
            </div>
          </div>
          <div style="position: absolute; top: 4px; right: 4px;">
            <v-icon class="white--text" @click="closeCard(index)">mdi-close</v-icon>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'notificationList',
  data () {
    return {
      items: [] // { title, body, isShow }
    }
  },
  methods: {
    closeCard (index) {
      this.items[index].isShow = false
      setTimeout(
        () => {
          this.items.splice(index, 1)
        }, 500
      )
    }
  },
  watch: {
    notificationQueue (to, from) {
      while (to.length >= 1) {
        const obj = { ...to[0], isShow: true }
        if (obj.click_action) {
          window.open(obj.click_action, '_self')
        } else {
          this.items.push(obj)
          this.$store.dispatch('notification/popQueue')
        }
      }
    }
  },
  computed: {
    ...mapState('notification', ['notificationQueue'])
  }
}
</script>

<style scoped>
.item-leave-active {
  transition: all 0.5s;
}

.item-leave-to /* .list-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: translateX(30px);
}

.item-enter-active, .item-enter {
  transition: translate 1s;
}
</style>
