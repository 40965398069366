<template>
  <div style="height: 100%; width: 100%;">
    <keep-alive v-if="keepAliveResetSignal">
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import utils from './assets/js/utils'

export default {
  name: 'App',
  async mounted () {
    await this.$store.dispatch('loadDeviceId') // deviceId load here
    await this.$store.dispatch('user/loadInternalStorage') // uuid load here
    this.getFCM()
  },
  data () {
    return {
      hasBeenSave: false
    }
  },
  methods: {
    getFCM () {
      const deviceType = utils.getDeviceType()
      if (deviceType === 3) {
        // if device is web-browser, call firebase js function
        if (this.$messaging) {
          this.$messaging.getToken({ vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY })
            .then(
              fcmToken => {
                this.processFCM(fcmToken)
              }
            )
            .catch(
              error => {
                console.warn('An error occurred while retrieving token. ', error)
              }
            )
        }
      } else if (deviceType === 1 || deviceType === 2) {
        // if device is mobile app, call js-interface-function
        window.longdoJsInterface.setOnRefreshFCMToken({
          onRefreshFCMToken: (fcmToken) => {
            this.processFCM(fcmToken)
          }
        })
        window.longdoJsInterface.getFCMToken() // this function invoke setOnRefreshFCMToken-callback with unknown time delay
      }
    },
    processFCM (fcmToken) {
      // set event when vue is receive notification
      this.setMessageCallback()
      // save FCM token to vue
      this.$store.commit('UPDATE_FCM_TOKEN', fcmToken)
    },
    setMessageCallback () {
      // set notification callback
      const deviceType = utils.getDeviceType()
      if (deviceType === 3) {
        if (this.$messaging) {
          this.$messaging.onMessage((payload) => {
            const message = JSON.parse(JSON.stringify(payload.notification))
            this.$store.dispatch('notification/pushQueue', message)
          })
        }
      } else if (deviceType === 1 || deviceType === 2) {
        window.longdoJsInterface.setOnMessageFCM({
          onMessageFCM: (messageData) => {
            const messageObject = JSON.parse(messageData)
            this.$store.dispatch('notification/pushQueue', messageObject)
          }
        })
        window.longdoJsInterface.readyOnMessageFCM()
      }
    },
    saveDeviceToBackend () {
      // if (fcm, device_id) is present, save to database
      const payload = {
        uuid: this.uuid || '',
        device: this.deviceId || '',
        token: this.firebaseCloudMessageToken || '',
        platform: utils.getDeviceType()
      }
      this.$store.dispatch('saveDeviceToBackend', payload)
    }
  },
  watch: {
    firebaseCloudMessageToken (to, from) {
      if (to && this.deviceId && !this.hasBeenSave) {
        this.hasBeenSave = true
        this.saveDeviceToBackend()
      }
    },
    deviceId (to, from) {
      if (to && this.firebaseCloudMessageToken && !this.hasBeenSave) {
        this.hasBeenSave = true
        this.saveDeviceToBackend()
      }
    }
  },
  computed: {
    ...mapState('user', ['uuid']),
    ...mapState(['firebaseCloudMessageToken', 'deviceId', 'keepAliveResetSignal'])
  }
}
</script>

<style>
/*https://github.com/vuetifyjs/vuetify/issues/11553#issuecomment-885661411*/
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit !important;
  padding: 6px 0 !important;
}

.highcharts-credits {
  display: none;
}

/* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_scrollbar */
@media (min-width: 601px) {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #0092be;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #005771;
  }
}

body, .v-application {
  font-family: "Roboto", 'Prompt', sans-serif !important;
}

body, html {
  height: 100%;
  overflow-y: hidden !important;
}

.v-application--wrap, .v-application, .v-main {
  height: 100%;
}

.v-application--wrap {
  min-height: unset !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 15px !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: rgba(0, 0, 0, 0.87);
}

.clickText {
  cursor: pointer;
}

.clickText:hover {
  text-decoration: underline;
}

.a_forRightClick {
  text-decoration: unset;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* [START] ./company map dialog */
.mapPopupTotalScore {
  padding: 6px;
  background-color: #27ae60;
  color: white;
}

.mapPopupCompanyDetail {
  padding: 6px;
  border-radius: 8px;
}

.mapPopupBlock {
  min-width: 32%;
  padding-right: 4px;
  padding-bottom: 4px;
}

.mapPopupValue {
  /* please see app.vue */
  padding: 6px;
  border-radius: 8px;
  border: 1px #9e9e9e solid;
}

.mapPopupNumber {
  font-weight: bold;
  font-size: 16px;
}

/* [END] ./company map dialog */

</style>
