import utils from '../../assets/js/utils'
import api from '../../assets/js/api'

const user = {
  namespaced: true,
  state: {
    email: '',
    full_name: '',
    permission: [],
    position: '',
    profile_picture: '',
    roles: [],
    status: null, // type number
    uid: null, // type number
    user_name: '',
    uuid: '',
    province_id: 0,
    factory_id: 0,
    isLoggingOut: false,
    hasBeenLoadInternal: false
  },
  getters: {},
  actions: {
    resetState ({ commit, state, dispatch }, payload) {
      const original = {
        email: '',
        full_name: '',
        permission: [],
        position: '',
        profile_picture: '',
        roles: [],
        status: null, // type number
        uid: null, // type number
        user_name: '',
        uuid: '',
        province_id: 0,
        factory_id: 0
      }
      Object.keys(original).forEach((key, index) => {
        state[key] = original[key]
      })
    },
    async doLogin ({ commit, state, dispatch }, payload) {
      const formData = utils.payloadToFormData({ user: payload.username, pass: payload.password, mode: payload.mode })
      const url = process.env.VUE_APP_WS_BASE + '/get/login'
      return await dispatch('requestLoginByUrlAndPayload', { url: url, formData: formData })
    },
    async doLoginUsingSid ({ commit, state, dispatch }, payload) {
      if (!payload.sid) {
        console.log('require [sid] get', payload)
      }
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/user-from-session'
      return await dispatch('requestLoginByUrlAndPayload', { url: url, formData: formData })
    },
    async requestLoginByUrlAndPayload ({ commit, state, dispatch }, payload) {
      if (!payload.url || !payload.formData) {
        console.log('require [rul, formData] get', payload)
      }
      const response = await api.post({ url: payload.url, data: payload.formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        const userData = data.data
        Object.keys(userData).forEach((key, index) => {
          state[key] = userData[key]
        })
        await dispatch('saveStorage', userData)
        return true
      } else {
        console.warn(data)
        return false
      }
    },
    async doLogout ({ commit, state, dispatch }, payload) {
      const formData = utils.payloadToFormData({ uuid: state.uuid })
      const url = process.env.VUE_APP_WS_BASE + '/get/logout'
      state.isLoggingOut = true
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        localStorage.removeItem('user')
        dispatch('resetState')
        state.isLoggingOut = false
        return true
      } else {
        state.isLoggingOut = false
        console.warn(data)
      }
    },
    async saveStorage ({ commit, state, dispatch }, userData) {
      // <editor-fold desc="save to browser">
      const userString = JSON.stringify(userData)
      localStorage.setItem('user', userString)
      // </editor-fold>
      // <editor-fold desc="save to phone">
      if (userData.profile_picture && userData.profile_picture.trim() !== '') {
        // <editor-fold desc="save profile picture to cache ">
        let isCacheSuccess = false
        try {
          isCacheSuccess = await window.longdoJsInterface.addCache({
            cacheName: 'user',
            url: userData.profile_picture
          })
        } catch (error) {
          if (error.code !== 1) {
            console.warn('user :: saveStorage :: addCache -->', error)
          }
        }
        if (isCacheSuccess) {
          try {
            userData.offlineProfilePicture = await window.longdoJsInterface.getCache({
              cacheName: 'user',
              url: userData.profile_picture,
              returnType: 'stringUrl'
            })
          } catch (error) {
            if (error.code !== 1) {
              console.warn('user :: saveStorage :: getCache -->', error)
            }
          }
        } else {
          console.warn('user :: saveStorage :: can not addCache')
        }
        // </editor-fold>
      }
      window.longdoJsInterface.addStorage({
        key: 'user',
        value: JSON.stringify(userData)
      }).then(
        () => {},
        (error) => {
          if (error.code !== 1) {
            console.warn('user :: saveStorage :: addStorage -->', error)
          }
        }
      )
      // </editor-fold>
    },
    async loadInternalStorage ({ commit, state, dispatch }, payload) {
      if (state.hasBeenLoadInternal) {
        return true
      }
      state.hasBeenLoadInternal = true
      if (window.longdoJsInterface.Util.isOnLine() === true) {
        const userData = JSON.parse(localStorage.getItem('user'))
        if (userData) {
          Object.keys(userData).forEach((key, index) => {
            state[key] = userData[key]
          })
          return true
        }
      } else {
        let userData = {}
        try {
          const result = await window.longdoJsInterface.getStorage({
            key: 'user'
          })
          userData = JSON.parse(result)
          userData.profile_picture = userData.offlineProfilePicture
        } catch (error) {
          console.warn(error)
        }
        if (userData) {
          Object.keys(userData).forEach((key, index) => {
            state[key] = userData[key]
          })
          return true
        }
      }
    }
  },
  mutations: {}
}

export default user
