import axios from 'axios'

export default {
  get (config) {
    const option = {
      url: config.url,
      data: config.data,
      method: 'get'
    }
    return this.doAxios(option)
  },
  post (config) {
    const option = {
      url: config.url,
      data: config.data,
      method: 'post'
    }
    return this.doAxios(option)
  },
  doAxios (config) {
    const defaultOption = {
      headers: { 'Content-Type': 'multipart/form-data' },
      validateStatus: function (status) {
        return true
      }
    }
    return axios({ ...defaultOption, ...config })
  }
}
