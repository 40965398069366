import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'

import firebase from 'firebase/app'
import firebaseMessaging from './firebase'

Vue.config.productionTip = false
window.longdoJsInterface = new (window.LongdoJsInterface)()

const initial = async () => {
  const longdoMapJsApiUrl = process.env.VUE_APP_LONGDO_MAP_PATH
  const martinezJsUrl = 'https://api.longdo.com/map/js/martinez.min.js'

  try {
    const isBrowserSupport = firebase.messaging.isSupported()
    if (isBrowserSupport && firebaseMessaging !== null) {
      navigator.serviceWorker.register(process.env.VUE_APP_FIREBASE_SW)
        .then(
          registration => {
            Vue.prototype.$messaging = firebaseMessaging
            Vue.prototype.$messaging.useServiceWorker(registration)
          }
        )
    }
  } catch (e) {
    console.warn('firebase se installing fail', e)
  }

  if (window.longdoJsInterface.Util.isOnLine() === true) { // on online
    // set offline files
    try {
      const result = await axios.get(process.env.VUE_APP_OFFLINE_FILE_LIST, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      await window.longdoJsInterface.setOffline(result.data)
    } catch (error) {
      if (error.code !== 1) {
        console.warn(error)
      }
    }

    // add longdo map api script
    await window.longdoJsInterface.Util.addScriptTag({
      src: longdoMapJsApiUrl,
      type: 'text/javascript',
      async: false,
      defer: false,
      charset: 'UTF-8'
    })

    try {
      // cache longdo map api script
      window.longdoJsInterface.addCache({
        cacheName: 'diw_front_cache',
        url: longdoMapJsApiUrl
      }).then(
        (result) => {},
        (error) => {
          if (error.code !== 1) {
            console.warn(error)
          }
        }
      )

      // cache martinez js
      window.longdoJsInterface.addCache({
        cacheName: 'diw_front_cache',
        url: martinezJsUrl
      }).then(
        (result) => {},
        (error) => {
          if (error.code !== 1) {
            console.warn(error)
          }
        }
      )
    } catch (e) {
      console.warn(e)
    }
  } else { // on offline
    // get cache of martinez js
    try {
      const result = await window.longdoJsInterface.getCache({
        cacheName: 'diw_front_cache',
        url: martinezJsUrl,
        returnType: 'stringUrl'
      })
      await window.longdoJsInterface.Util.addScriptTag({
        src: result,
        type: 'text/javascript',
        async: false,
        defer: false,
        charset: 'UTF-8'
      })
    } catch (error) {
      console.warn(error)
    }

    // get cache of longdo map api script
    try {
      const result = await window.longdoJsInterface.getCache({
        cacheName: 'diw_front_cache',
        url: longdoMapJsApiUrl,
        returnType: 'stringUrl'
      })
      await window.longdoJsInterface.Util.addScriptTag({
        src: result,
        type: 'text/javascript',
        async: false,
        defer: false,
        charset: 'UTF-8'
      })
    } catch (error) {
      console.warn(error)
    }
  }

  // manage cache longdo map theme (image url)
  await (window.longdoJsInterface.Util.cacheLongdoMapTheme(window.longdo, 'diw_front_cache'))

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}

initial()
