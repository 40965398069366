<template>
  <v-dialog v-model="isOpenDialog" max-width="800">
    <v-card>
      <v-card-title>
        <span class="secondary--text">ค้นหาขั้นสูง</span>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-0">
        <div class="py-4 px-6 px-sm-16 black--text">
          <v-row class="pt-3 pt-sm-0">
            <v-col class="pb-0 pb-sm-3 pt-0 pt-sm-3 d-flex align-sm-center text-left text-sm-right justify-sm-end"
                   cols="12" sm="3">
              ประเภทอุตสาหกรรม
            </v-col>
            <v-col class="pt-2 pt-sm-3" cols="12" sm="">
              <v-autocomplete v-model="selectedFactoryType" :items="forSelectFactoryType" color="search" dense
                              hide-details item-color="search-item" item-text="name" item-value="id" outlined
                              placeholder="--เลือก--"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pb-sm-3 pt-0 pt-sm-3 d-flex align-sm-center text-left text-sm-right justify-sm-end"
                   cols="12" sm="3">
              การประกอบกิจการ
            </v-col>
            <v-col class="pt-2 pt-sm-3" cols="12" sm="">
              <v-autocomplete v-model="selectedIndustryType" :items="forSelectIndustryType" color="search" dense
                              hide-details item-color="search-item" item-text="name" item-value="id" outlined
                              placeholder="--เลือก--"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-none d-sm-flex align-center text-right justify-end" cols="4" sm="3">
              ภาค
            </v-col>
            <v-col class="pt-1 pt-sm-3" cols="6" sm="4">
              <span class="d-block d-sm-none">ภาค</span>
              <v-select v-model="selectedRegion" :items="forSelectRegion" class="mt-2 mt-sm-0" color="search"
                        dense hide-details item-color="search-item" item-text="name" item-value="id" outlined
                        placeholder="--เลือก--"></v-select>
            </v-col>
            <v-col class="d-none d-sm-flex align-center text-right justify-end" cols="4" sm="auto">
              จังหวัด
            </v-col>
            <v-col class="pt-1 pt-sm-3" cols="6" sm="">
              <span class="d-block d-sm-none">จังหวัด</span>
              <v-autocomplete v-model="selectedProvince" :items="forSelectProvince" class="mt-2 mt-sm-0" color="search"
                              dense hide-details item-color="search-item" item-text="name" item-value="id" outlined
                              placeholder="--เลือก--"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-none d-sm-flex align-center text-right justify-end" cols="4" sm="3">
              เขต/อำเภอ
            </v-col>
            <v-col class="pt-1 pt-sm-3" cols="6" sm="4">
              <span class="d-block d-sm-none">เขต/อำเภอ</span>
              <v-autocomplete v-model="selectedDistrict" :items="forSelectDistrict" class="mt-2 mt-sm-0" color="search"
                              dense hide-details item-color="search-item" item-text="name" item-value="id" outlined
                              placeholder="--เลือก--"/>
            </v-col>
<!--            <v-col class="d-none d-sm-flex align-center text-right justify-end" cols="4" sm="">-->
<!--              เฉพาะโรงงานที่มีผลการตรวจวัดล่าสุด-->
<!--            </v-col>-->
            <v-col class="d-flex justify-sm-end" cols="12" sm="">
              <v-checkbox v-model="hasLatestMeasurement" class="ma-0" hide-details>
                <template v-slot:label>
                  <span class="black--text" style="font-size: 14px;">เฉพาะโรงงานที่มีผลการตรวจวัดล่าสุด</span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex justify-end pb-1" style="width: 100%">
          <v-btn elevation="0" @click="isOpenDialog=false">
            ปิด
          </v-btn>
          <v-btn class="ml-3" color="search-item white--text" elevation="0" @click="search()">
            ค้นหา
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AdvanceSearchDialog',
  props: {
    isShow: Boolean
  },
  mounted () {
    this.$store.dispatch('ref/getIndustryType')
    this.$store.dispatch('ref/getFactoryType')
    this.$store.dispatch('getProvince')
    this.$store.dispatch('getRegion')
  },
  data () {
    return {
      data: {},
      isOpenDialog: false,
      selectedFactoryType: null,
      selectedFactoryType_old: null,
      selectedIndustryType: null,
      selectedIndustryType_old: null,
      selectedRegion: null,
      selectedRegion_old: null,
      selectedProvince: null,
      selectedProvince_old: null,
      selectedDistrict: null,
      selectedDistrict_old: null,
      hasLatestMeasurement: false,
      hasLatestMeasurement_old: false
    }
  },
  methods: {
    search () {
      const payload = {
        type: this.selectedFactoryType || '',
        type_industry: this.selectedIndustryType || '',
        region: this.selectedRegion || '',
        geocode: this.selectedDistrict || this.selectedProvince || '',
        has_latest_measurement: this.hasLatestMeasurement
      }
      this.$emit('advanceSearchClick', payload)
      // save parameter for later open dialog
      this.selectedFactoryType_old = this.selectedFactoryType
      this.selectedIndustryType_old = this.selectedIndustryType
      this.selectedProvince_old = this.selectedProvince
      this.selectedRegion_old = this.selectedRegion
      this.selectedDistrict_old = this.selectedDistrict
      this.hasLatestMeasurement_old = this.hasLatestMeasurement
      this.isOpenDialog = false
    }
  },
  watch: {
    isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.isOpenDialog = newVal
      }
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
      if (newVal === true) {
        // load saved parameter
        this.selectedFactoryType = this.selectedFactoryType_old
        this.selectedIndustryType = this.selectedIndustryType_old
        this.selectedProvince = this.selectedProvince_old
        this.selectedRegion = this.selectedRegion_old
        this.selectedDistrict = this.selectedDistrict_old
        this.hasLatestMeasurement = this.hasLatestMeasurement_old
      }
    },
    selectedRegion (to, from) {
      if (to !== from) {
        this.selectedProvince = null
        this.selectedDistrict = null
      }
    },
    selectedProvince (to, from) {
      if (to === '') {
        this.selectedDistrict = ''
      } else {
        if (to !== null) {
          this.$store.dispatch('getDistrict', { id_parent: to })
        }
      }
    }
  },
  computed: {
    ...mapState('ref', ['industryType', 'factoryType']),
    ...mapState(['province', 'district', 'region']),
    forSelectRegion () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.region]
    },
    forSelectFactoryType () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.factoryType]
    },
    forSelectIndustryType () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.industryType]
    },
    forSelectProvince () {
      if (this.selectedRegion) {
        const remaining = this.province.filter(item => String(item.id_parent) === String(this.selectedRegion))
        return [{ id: '', name: 'ทั้งหมด' }, ...remaining]
      } else {
        return [{ id: '', name: 'ทั้งหมด' }, ...this.province]
      }
    },
    forSelectDistrict () {
      return [{ id: '', name: 'ทั้งหมด' }, ...this.district]
    }
  }
}
</script>

<style scoped>

</style>
