<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :disabled="readonly" :return-value.sync="date" min-width="auto" offset-y
          transition="scale-transition"> <!-- close-on-content-click need to be false -->
    <template v-slot:activator="{ on, attrs }">
      <v-text-field ref="inp" v-model="showDate" v-bind="attrs" v-on="on" :color="color" :rules="rules"
                    append-icon="mdi-calendar-clock" background-color="white" dense hide-details outlined
                    placeholder="--เลือก--" readonly @click:append="$refs.inp.$refs.input.click()"></v-text-field>
    </template>
    <v-date-picker v-model="date" :color="color" :full-width="$vuetify.breakpoint.xs" :header-color="headerColor"
                   :max="max" :min="min" :show-current="false" scrollable no-title>
      <v-spacer></v-spacer>
      <v-btn text @click="menu = false">
        ปิด
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import utils from '../assets/js/utils'

export default {
  name: 'myDatePicker',
  props: {
    value: String,
    color: {
      type: String,
      default: 'search'
    },
    headerColor: {
      type: String,
      default: 'search'
    },
    buttonColor: {
      type: String,
      default: 'search-item white--text'
    },
    min: {
      type: String,
      default: '2021-10-01'
    },
    max: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    rules: {
      type: Array,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    if (this.value) {
      this.date = this.value
    }
  },
  data () {
    return {
      // date: new Date().toISOString().substr(0, 10),
      date: '',
      menu: false
    }
  },
  watch: {
    value (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.date = newVal
      }
    },
    date (newVal, oldVal) {
      this.$emit('input', this.date)
      this.$refs.menu.save(newVal)
    }
  },
  computed: {
    showDate: {
      get () {
        if (!this.date) {
          return ''
        }
        const date = utils.dateToThai(this.date)
        this.$emit('input', this.date)
        return date
      },
      set (newVal) {
        if (!newVal) {
          this.date = ''
        }
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-date-picker-table {
  height: unset;
}

::v-deep .v-picker__actions .v-card__actions {
  padding-top: 0;
}
</style>
