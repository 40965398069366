import utils from '../../assets/js/utils'
import api from '../../assets/js/api'

const notification = {
  namespaced: true,
  state: {
    notificationQueue: []
  },
  getters: {},
  actions: {
    popQueue ({ commit, state, dispatch }, payload) {
      state.notificationQueue.shift()
    },
    pushQueue ({ commit, state, dispatch }, payload) {
      state.notificationQueue.push(payload)
    },
    async getNotificationsLogList ({ commit, state, dispatch }, payload) {
      if (!payload.uuid) {
        console.warn('require key [uuid]', payload)
      }
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/notification-log-list'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getNotificationList ({ commit, state, dispatch }, payload) {
      if (!payload.uuid) {
        console.warn('require key [uuid]', payload)
      }
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/notification-list'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async setNotificationStatus ({ commit, state, dispatch }, payload) {
      if (!payload.notificationId || ![-1, 1, 0].includes(parseInt(payload.action)) || !payload.uuid) {
        console.warn('require key [notificationId, action, uuid] get', payload)
      }
      const id = payload.notificationId
      delete payload.notificationId
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/set/approve-sending-notification/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return false
      }
    }
  },
  mutations: {}
}

export default notification
