import utils from '../../assets/js/utils'
import api from '../../assets/js/api'

const eReport = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    async getEReportList ({ commit, state, dispatch }, payload) {
      if (!payload.uuid || !payload.type) {
        console.warn('require key [uuid, type]', payload)
        return {}
      }
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/ereport-list'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getEReport ({ commit, state, dispatch }, payload) {
      if (!payload.uuid || !payload.sampling_location_id) {
        console.warn('require key [uuid, sampling_location_id]', payload)
        return {}
      }
      const id = payload.sampling_location_id
      delete payload.sampling_location_id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/sampling-location/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async setEReport ({ commit, state, dispatch }, payload) {
      const id = payload.sampling_location_id
      delete payload.sampling_location_id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/set/sampling-location/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return data.message
      }
    },
    async deleteEReport ({ commit, state, dispatch }, payload) {
      if (!payload.uuid || !payload.sampling_location_id) {
        console.warn('require key [uuid, sampling_location_id]', payload)
        return false
      }
      const id = payload.sampling_location_id
      delete payload.sampling_location_id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/del/sampling-location/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return false
      }
    },
    async getInnerEReportList ({ commit, state, dispatch }, payload) {
      if (!payload.uuid || !payload.sampling_location_id) {
        console.warn('require key [uuid, sampling_location_id]', payload)
        return {}
      }
      const id = payload.sampling_location_id
      delete payload.sampling_location_id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/ereport-sampling/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getInnerEReportModal ({ commit, state, dispatch }, payload) {
      if (!payload.uuid || !payload.sampling_location_id) {
        console.warn('require key [uuid, sampling_location_id]', payload)
        return {}
      }
      const id = payload.sampling_location_id
      delete payload.sampling_location_id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/ereport/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async setInnerEReportModal ({ commit, state, dispatch }, payload) {
      const id = payload.report_id
      delete payload.report_id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/set/ereport/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return data.message
      }
    },
    async deleteInnerEReport ({ commit, state, dispatch }, payload) {
      if (!payload.uuid || !payload.rowId) {
        console.warn('require key [uuid, rowId]', payload)
        return false
      }
      const rowId = payload.rowId
      delete payload.rowId
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/del/ereport/' + rowId
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return false
      }
    }
  },
  mutations: {}
}

export default eReport
