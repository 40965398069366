import utils from '../../assets/js/utils'
import api from '../../assets/js/api'

const statistic = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    async getDailyStat ({ state, commit, dispatch }, payload) {
      if (!payload.type || !payload.parameter) {
        console.warn('require key [type, parameter]', payload)
        return {}
      }
      if (window.longdoJsInterface.Util.isOnLine() === true) {
        const formData = utils.payloadToFormData(payload)
        const url = process.env.VUE_APP_WS_BASE + '/get/stat'
        const response = await api.post({ url: url, data: formData })
        const data = response.data
        if (data.code === 'SUCCESS') {
          dispatch('saveStatData', data.data)
          return data.data
        } else {
          console.warn(data)
          return {}
        }
      } else {
        return await dispatch('loadStatData')
      }
    },
    async saveStatData ({ state, commit, dispatch }, payload) {
      window.longdoJsInterface.addStorage({
        key: 'dailyStat',
        value: JSON.stringify(payload)
      }).then(
        () => {},
        (error) => {
          if (error.code !== 1) {
            console.warn('saveStatData :: addStorage -->', error)
          }
        }
      )
    },
    async loadStatData ({ state, commit, dispatch }, payload) {
      let data = { values: [] }
      try {
        const result = await window.longdoJsInterface.getStorage({
          key: 'dailyStat'
        })
        data = JSON.parse(result)
      } catch (error) {
        console.warn(error)
      }
      return data
    },
    async getEReportStat ({ state, commit, dispatch }, payload) {
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/stat-ereport'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    }
  }
}

export default statistic
