import axios from 'axios'
import utils from '../../assets/js/utils'
import api from '../../assets/js/api'

const ref = {
  namespaced: true,
  state: {
    personType: [],
    hasBeenLoadMeasurementType: false,
    measurementType: [],
    hasBeenLoadParameterList: false,
    parameterList: [],
    parameter: {},
    industryType: [],
    factoryType: [],
    deviceType: [],
    hasBeenLoadFac: false,
    factory: [],
    allFactory: [],
    treatmentRef: [],
    stackFuelRef: [],
    requestRef: [],
    systemFuelStatus: [],
    hasBeenLoadFacByPro: false,
    factoryByProvince: {},
    colonyIndustry: [],
    zone: [],
    entireParameter: {
      1: [],
      2: [],
      3: [],
      4: []
    },
    factoryFromFactoryType: {},
    eReportSamplingType: {}
  },
  getters: {
    isOtherDeviceType: (state) => (id) => {
      let selected = state.deviceType.filter(item => String(item.id) === String(id))
      if (selected.length <= 0) {
        return false
      }
      selected = selected[0]

      return !!selected.name.includes('อื่น')
    }
  },
  actions: {
    async getSystemFuelStatus ({ commit, state, dispatch }, payload) {
      if (state.systemFuelStatus.length > 0) {
        return state.systemFuelStatus
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/system_fuel'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.systemFuelStatus = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getPersonType ({ commit, state, dispatch }, payload) {
      if (state.personType.length > 0) {
        return state.personType
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/person_type'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.personType = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async loadMeasurementType ({ commit, state, dispatch }, payload) {
      if (!state.hasBeenLoadMeasurementType && !window.longdoJsInterface.Util.isOnLine()) {
        try {
          const result = await window.longdoJsInterface.getStorage({
            key: 'ref-measurementType'
          })
          state.measurementType = JSON.parse(result) || []
        } catch (e) {
          if (e.code !== 1) {
            console.warn('load-measurementType -->', e)
          }
        }
        state.hasBeenLoadMeasurementType = true
      }
    },
    saveMeasurementType ({ commit, state, dispatch }, payload) {
      window.longdoJsInterface.addStorage({ key: 'ref-measurementType', value: JSON.stringify(state.measurementType) })
        .then(
          () => {},
          (error) => {
            if (error.code !== 1) {
              console.warn('save-measurementType -->', error)
            }
          }
        )
    },
    async getMeasurementType ({ commit, state, dispatch }, payload) {
      await dispatch('loadMeasurementType')
      if (state.measurementType.length > 0) {
        return state.measurementType
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/measurement_type'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.measurementType = data.data
        dispatch('saveMeasurementType')
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async loadAllParameterList ({ commit, state, dispatch }, payload) {
      if (!state.hasBeenLoadParameterList && !window.longdoJsInterface.Util.isOnLine()) {
        try {
          const result = await window.longdoJsInterface.getStorage({
            key: 'ref-parameterList'
          })
          state.parameterList = JSON.parse(result) || []
        } catch (e) {
          if (e.code !== 1) {
            console.warn('load-parameterList -->', e)
          }
        }
        state.hasBeenLoadParameterList = true
      }
    },
    saveAllParameterList ({ commit, state, dispatch }, payload) {
      window.longdoJsInterface.addStorage({ key: 'ref-parameterList', value: JSON.stringify(state.parameterList) })
        .then(
          () => {},
          (error) => {
            if (error.code !== 1) {
              console.warn('save-parameterList -->', error)
            }
          }
        )
    },
    async getParameterList ({ commit, state, dispatch }, payload) {
      await dispatch('loadAllParameterList')
      const idParent = (payload) ? payload.id_parent : false
      if (!idParent && state.parameterList.length > 0) {
        return state.parameterList
      }
      let url = process.env.VUE_APP_WS_BASE
      if (idParent) {
        url += '/get/ref/parameter?id_parent=' + idParent
      } else {
        url += '/get/ref/parameter'
      }
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        if (!idParent) {
          state.parameterList = data.data
          dispatch('saveAllParameterList')
        }
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getParameterListByMeasurementType ({ commit, state, dispatch }, payload) {
      if (!payload.id_parent) {
        console.warn('require [id_parent] get', payload)
        return false
      }
      const idParent = payload.id_parent
      if (state.parameter[String(idParent)]) {
        return state.parameter[String(idParent)]
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/parameter_measurement?id_parent=' + idParent
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.parameter[String(idParent)] = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getIndustryType ({ commit, state, dispatch }, payload) {
      if (state.industryType.length > 0) {
        return state.industryType
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/industry_type'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.industryType = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getFactoryType ({ state, commit, dispatch }, payload) {
      if (state.factoryType.length > 0) {
        return state.factoryType
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/factory_type'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.factoryType = data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getDeviceType ({ commit, state, dispatch }, payload) {
      if (state.deviceType.length > 0) {
        return state.deviceType
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/device'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.deviceType = data.data
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getFactoryAll ({ commit, state, dispatch }, payload) {
      if (state.allFactory.length > 0) {
        return state.allFactory
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/factory_all'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.allFactory = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getFactoryByFactoryType ({ commit, state, dispatch }, payload) {
      const idParent = payload.id_parent
      if (Array.isArray(state.factoryFromFactoryType[idParent]) && state.factoryFromFactoryType[idParent].length) {
        return state.factoryFromFactoryType[idParent]
      }
      const url = process.env.VUE_APP_WS_BASE + `/get/ref/factory?id_parent=${idParent}&key=factoryType`
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.factoryFromFactoryType[idParent] = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async loadFactoryRef ({ commit, state, dispatch }, payload) {
      if (!state.hasBeenLoadFac && !window.longdoJsInterface.Util.isOnLine()) {
        try {
          const result = await window.longdoJsInterface.getStorage({
            key: 'ref-factory'
          })
          state.factory = JSON.parse(result) || []
        } catch (e) {
          if (e.code !== 1) {
            console.warn('load-factory -->', e)
          }
        }
        state.hasBeenLoadFac = true
      }
    },
    saveFactoryRef ({ commit, state, dispatch }, payload) {
      window.longdoJsInterface.addStorage({ key: 'ref-factory', value: JSON.stringify(state.factory) })
        .then(
          () => {},
          (error) => {
            if (error.code !== 1) {
              console.warn('save-factory -->', error)
            }
          }
        )
    },
    async getFactoryRef ({ commit, state, dispatch }, payload) {
      await dispatch('loadFactoryRef')
      if (state.factory.length > 0) {
        return state.factory
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/factory'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.factory = data.data
        dispatch('saveFactoryRef')
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getFactoryByRegionId ({ commit, state, dispatch }, payload) {
      const regionId = payload.regionId
      const url = process.env.VUE_APP_WS_BASE + `/get/ref/factory?id_parent=${regionId}&key=region`
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.treatmentRef = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getFactoryByProvinceId ({ commit, state, dispatch }, payload) {
      const provinceId = payload.provinceId
      const oldData = state.factoryByProvince[String(provinceId)]
      if (provinceId && oldData) {
        return oldData
      }
      let url = process.env.VUE_APP_WS_BASE
      if (provinceId && provinceId !== 0) {
        url += '/get/ref/factory?id_parent=' + provinceId + '&key=province'
      } else {
        url += '/get/ref/factory'
      }
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        if (provinceId) {
          state.factoryByProvince[String(provinceId)] = data.data
        }
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getTreatmentSystemRef ({ commit, state, dispatch }, payload) {
      if (state.treatmentRef.length > 0) {
        return state.treatmentRef
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/treatment_system'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.treatmentRef = data.data
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getStackFuelRef ({ commit, state, dispatch }, payload) {
      if (state.stackFuelRef.length > 0) {
        return state.stackFuelRef
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/stack_fuel'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.stackFuelRef = data.data
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async measurement ({ commit, state, dispatch }, payload) {
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/measurement'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async measurementByQuery ({ commit, state, dispatch }, payload) {
      const formData = new FormData()
      Object.keys(payload).forEach(key => {
        formData.append('id_parent_group[]', payload[key])
        formData.append('key_group[]', key)
      })
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/measurement'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async request ({ commit, state, dispatch }, payload) {
      if (state.requestRef.length > 0) {
        return state.requestRef
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/request'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.requestRef = data.data
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getColony ({ commit, state, dispatch }, payload) {
      if (state.colonyIndustry.length > 0) {
        return state.colonyIndustry
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/colony_industry'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.colonyIndustry = data.data
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getZone ({ commit, state, dispatch }, payload) {
      if (state.zone.length > 0) {
        return state.zone
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/zone'
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.zone = data.data
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async searchEntireFactory ({ commit, state, dispatch }, payload) {
      let searchParams = {
        key: 'keyword',
        id_parent: payload.keyword
      }
      searchParams = new URLSearchParams(searchParams)
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/factory_entire?' + searchParams.toString()
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getEntireParameter ({ commit, state, dispatch }, payload) {
      const type = String(payload.type)
      if (state.entireParameter[type].length > 0) {
        return state.entireParameter[type]
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/ereport_parameter?id_parent=' + type
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.entireParameter[type] = data.data
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getSubeteEntireParameter ({ commit, state, dispatch }, payload) {
      const keys = Object.keys(state.entireParameter)
      let totalParameter = []
      for (const key of keys) {
        if (state.entireParameter[key].length > 0) {
          totalParameter = [...totalParameter, ...state.entireParameter[key]]
        } else {
          const data = await dispatch('getEntireParameter', { type: key })
          totalParameter = [...totalParameter, ...data]
        }
      }
      const uniqueParameter = totalParameter.reduce(
        (acc, cur) => {
          if (acc.checker.indexOf(cur.id) < 0) {
            acc.data.push(cur)
            acc.checker.push(cur.id)
          }
          return acc
        }, { checker: [], data: [] }
      )
      return uniqueParameter.data
    },
    async getSamplingLocation ({ commit, state, dispatch }, payload) {
      const formData = new FormData()
      Object.keys(payload).forEach(key => {
        formData.append('id_parent_group[]', payload[key])
        formData.append('key_group[]', key)
      })
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/sampling_location'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getEReportSamplingType ({ commit, state, dispatch }, payload) {
      if (state.eReportSamplingType[payload.type] && state.eReportSamplingType[payload.type].length > 0) {
        return state.eReportSamplingType[payload.type]
      }
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/ereport_sampling_point?id_parent=' + payload.type
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.eReportSamplingType[payload.type] = data.data
        return data.data
      } else {
        console.warn(data)
        return []
      }
    }
  }
}

export default ref
