import utils from '../../assets/js/utils'
import api from '../../assets/js/api'

const manage = {
  namespaced: true,
  state: {},
  actions: {
    async setFactoryVisible ({ state, commit, dispatch }, payload) {
      if (!payload.id || ![1, 0].includes(payload.visible) || !payload.uuid) {
        console.warn('require [id, visible, uuid] get', payload)
        return false
      }
      const id = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/set/manage/factory-visible/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return false
      }
    },
    async getFactoryList ({ state, commit, dispatch }, payload) {
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/manage/factory-list'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async doQueryFactory ({ state, commit, dispatch }, payload) {
      const id = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/set/manage/factory/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return data.message
      }
    },
    async doDeleteFactory ({ state, commit, dispatch }, payload) {
      const id = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/del/manage/factory/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return false
      }
    },
    async getDeviceList ({ state, commit, dispatch }, payload) {
      if (!payload.uuid) {
        console.warn('require key [uuid]', payload)
        return []
      }
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/manage/device-list'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getDevice ({ state, commit, dispatch }, payload) {
      if (!payload.id || !payload.uuid) {
        console.warn('require key [id, uuid]', payload)
        return {}
      }
      const id = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/device/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async setDevice ({ commit, state, dispatch }, payload) {
      if (!payload.uuid || !payload.ip_addr || !payload.converter || !payload.device || !payload.factory) {
        console.log('require key [uuid, ip_addr, converter, device, factory, id] get', payload)
        return false
      }
      if (!payload.id && payload.id !== 0) {
        console.log('require key id', payload)
        return false
      }
      const id = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/set/manage/device/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return data.message
      }
    },
    async deleteDevice ({ commit, state, dispatch }, payload) {
      if (!payload.id || !payload.uuid) {
        console.log('require key [id, uuid] get', payload)
        return false
      }
      const id = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/del/manage/device/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return false
      }
    },
    async getMeasurementList ({ state, commit, dispatch }, payload) {
      if (!payload.uuid || !payload.id) {
        console.warn('require key [uuid, id] get', payload)
        return []
      }
      // const query = new URLSearchParams(payload).toString()
      const factoryId = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/manage/measurement-list/' + factoryId
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getMeasurement ({ commit, state, dispatch }, payload) {
      if (!payload.uuid || !payload.id) {
        console.log('require key [uuid, id] get', payload)
        return false
      }
      const id = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/measurement/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return false
      }
    },
    async getInstrumentList ({ commit, state, dispatch }, payload) {
      if (!payload.uuid) {
        console.log('require key [uuid,] get', payload)
        return false
      }
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/manage/instrument-list'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return false
      }
    },
    async getInstrument ({ commit, state, dispatch }, payload) {
      if (!payload.uuid) {
        console.log('require key [uuid,id] get', payload)
        return false
      }
      const id = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/instrument/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async doQueryInstrument ({ state, commit, dispatch }, payload) {
      const id = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/set/manage/instrument/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return data.message
      }
    },
    async deleteInstrument ({ state, commit, dispatch }, payload) {
      if (!payload.id || !payload.uuid) {
        console.log('require key [uuid,] get', payload)
        return false
      }
      const id = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/del/manage/instrument/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return false
      }
    },
    async getFactoryDetail ({ state, commit, dispatch }, payload) {
      if (!payload.id || !payload.uuid) {
        console.log('require key [uuid,] get', payload)
        return false
      }
      const id = payload.id
      delete payload.id
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/get/factory/' + id
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    }
  },
  mutations: {}
}

export default manage
