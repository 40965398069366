import defaultFactoryImage from '../../assets/img/default_factory.png'
import utils from '../../assets/js/utils'
import api from '../../assets/js/api'

const company = {
  namespaced: true,
  state: {
    offlineKey: 'dataCompany',
    companyDataList: {},
    hasBeenFetchList: false
  },
  getters: {},
  actions: {
    async fetchCompanyDetail ({ commit, state, dispatch }, payload) {
      const factoryId = payload.id
      await dispatch('loadCompany')
      if (state.companyDataList[factoryId]) {
        return state.companyDataList[factoryId]
      }
      if (window.longdoJsInterface.Util.isOnLine() === true) {
        const formData = utils.payloadToFormData({ uuid: payload.uuid })
        const url = process.env.VUE_APP_WS_BASE + '/get/factory/' + payload.id
        const response = await api.post({ url: url, data: formData })
        const ret = response.data
        if (ret.code === 'SUCCESS') {
          const data = ret.data
          data.image = data.images
          if (data.image.length <= 0) {
            data.image.push({
              src: defaultFactoryImage,
              description: 'ไม่มีรูปภาพ'
            })
          }
          data.fetchedTime = new Date().getTime()
          state.companyDataList[factoryId] = data
          dispatch('saveFactory')
          return data
        } else {
          console.warn('fetching error', ret)
          return {}
        }
      } else {
        console.warn('no previous data and no internet connection')
        return {}
      }
    },
    async loadCompany ({ commit, state, dispatch }, payload) {
      if (!state.hasBeenFetchList) {
        return false
      }
      try {
        const isOnline = window.longdoJsInterface.Util.isOnLine()
        const result = await window.longdoJsInterface.getStorage({
          key: state.offlineKey
        })
        const newData = JSON.parse(result)
        if (isOnline) {
          // remove old data
          const factoryIdList = Object.keys(newData)
          const currentTime = new Date().getTime()
          for (const factoryId of factoryIdList) {
            if (!newData[factoryId].fetchedTime) {
              delete newData[factoryId]
              continue
            }
            const differentTime = currentTime - newData[factoryId].fetchedTime
            const differentHour = parseFloat(differentTime / 3600)
            if (differentHour > 1) {
              delete newData[factoryId]
              continue
            }
          }
        }
        if (!isOnline) {
          // use local image instead of api image
          const factoryIdList = Object.keys(newData)
          for (const factoryId of factoryIdList) {
            newData[factoryId].logo = newData[factoryId].offlineLogo
          }
        }
        state.companyDataList = newData
      } catch (error) {
        if (error.code !== 1) {
          console.warn('loadCompany :: getStorage -->', { ...error, myLogger: 'This device does not have any previous companies data.' })
        }
      }
    },
    async saveFactory ({ commit, state, dispatch }, payload) {
      const factoryIdList = Object.keys(state.companyDataList)
      for (const factoryId of factoryIdList) {
        let isSaveLogoSuccess = false
        try {
          isSaveLogoSuccess = await window.longdoJsInterface.addCache({
            cacheName: state.offlineKey,
            url: state.companyDataList[factoryId].logo
          })
        } catch (e) {
          if (e.code !== 1) {
            console.warn('saveFactory :: addCache -->', e)
          }
        }
        if (isSaveLogoSuccess) {
          try {
            const offlineLogo = await window.longdoJsInterface.getCache({
              cacheName: state.offlineKey,
              url: state.companyDataList[factoryId].logo,
              returnType: 'stringUrl' // return file path
            })
            state.companyDataList[factoryId].offlineLogo = offlineLogo
          } catch (e) {
            if (e.code !== 1) {
              console.warn('saveFactory :: getCache -->', e)
            }
          }
        }
      }
      window.longdoJsInterface.addStorage({
        key: state.offlineKey,
        value: JSON.stringify(state.companyDataList)
      }).then(
        () => {},
        (error) => {
          if (error.code !== 1) {
            console.warn('saveFactory :: addStorage -->', error)
          }
        }
      )
    },
    async getNotificationList ({ commit, state, dispatch }, payload) {
      return await dispatch('notification/getNotificationList', { ...payload, fromCompany: true }, { root: true })
    }
  },
  mutations: {}
}

export default company
