import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import Vuetify from 'vuetify/lib'
import th from 'vuetify/es5/locale/th'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { th },
    current: 'th'
  },
  theme: {
    themes: {
      light: {
        primary: '#005771',
        secondary: '#27AE60',
        search: '#1867C0',
        'search-other': '#7DB6FF',
        'search-item': '#2085F3',
        success: '#73BF12',
        warning: '#FFC700',
        error: '#BF1C12',
        gold: '#A08B4F'
      }
    }
  }
})
