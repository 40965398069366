<template>
  <v-dialog v-model="isOpenDialog" max-width="1200" scrollable>
    <v-card>
      <v-card-title>
        <span class="secondary--text">สืบค้นข้อมูลมลพิษ</span>
        <v-spacer/>
        <v-icon @click="isOpenDialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pb-0">
        <v-form ref="searchForm">
          <div class="black--text">
            <v-row class="ma-0 pt-sm-4">
              <v-col cols="12" sm="7">
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="3">
                    ชื่อ/เลขทะเบียนโรงงาน
                  </v-col>
                  <v-col cols="12" sm="9">
                    <v-text-field v-model="data.factory" dense hide-details outlined placeholder="ระบุ"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="3">
                    ประกอบกิจการ
                  </v-col>
                  <v-col cols="12" sm="9">
                    <v-text-field v-model="data.business" dense hide-details outlined placeholder="ระบุ"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="3">
                    ประเภทอุตสาหกรรม
                  </v-col>
                  <v-col cols="12" sm="9">
                    <v-autocomplete v-model="data.type_factory" :items="factoryType" dense hide-details item-text="name" item-value="id" outlined
                                    placeholder="ระบุ"></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="3">ภูมิภาค
                  </v-col>
                  <v-col cols="12" md="" sm="9">
                    <v-select v-model="data.region" :items="region" dense hide-details item-text="name" item-value="id"
                              outlined placeholder="ระบุ"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex align-center justify-sm-end text-sm-right pb-0 pb-md-3" cols="12" md="3" sm="3">
                    จังหวัด
                  </v-col>
                  <v-col cols="12" md="" sm="9">
                    <v-autocomplete v-model="data.province" :items="forSelectProvince" dense hide-details
                                    item-text="name" item-value="id" outlined placeholder="ระบุ"></v-autocomplete>
                  </v-col>
                  <v-col class="d-flex align-center justify-sm-end text-sm-right pb-0 pb-md-3" cols="12" md="auto" sm="3">
                    เขต/อำเภอ
                  </v-col>
                  <v-col cols="12" md="" sm="9">
                    <v-autocomplete v-model="data.district" :items="district" dense hide-details item-text="name" item-value="id" outlined
                                    placeholder="ระบุ"></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="3">
                    นิคมอุตสาหกรรม
                  </v-col>
                  <v-col cols="12" sm="9">
                    <v-autocomplete v-model="data.colony" :items="colonyIndustry" dense hide-details outlined placeholder="ระบุ" item-value="id" item-text="name"></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="3">
                    เขต/สวนอุตสาหกรรม
                  </v-col>
                  <v-col cols="12" sm="9">
                    <v-autocomplete v-model="data.zone" :items="zone" dense hide-details outlined placeholder="ระบุ" item-value="id" item-text="name"></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="3">
                    ยี่ห้อเครื่องตรวจวัด
                  </v-col>
                  <v-col cols="12" sm="9">
                    <v-text-field v-model="data.brand" dense hide-details outlined placeholder="ระบุ"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="5">
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="4">
                    ประเภทการตรวจวัด
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-select v-model="data.meas_type" :items="measurementTypeOptions" dense hide-details item-text="name"
                              item-value="id" outlined placeholder="ระบุ" @change="data.parameter=[]"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="4">
                    พารามิเตอร์ <span class="red--text">*</span>
                  </v-col>
                  <v-col cols="12" sm="8" style="max-height: 250px; overflow-y: auto;">
                    <v-select v-model="data.parameter" :items="parameterOptions" :rules="[rules.requireOne]" chips deletable-chips dense
                              hide-details item-text="name" item-value="id" multiple outlined placeholder="--เลือก--"
                              small-chips></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="4">
                    เชื้อเพลิง
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-select v-model="data.stack_fuel" :items="stackFuelRef" dense hide-details item-text="name" item-value="id" outlined
                              placeholder="ระบุ"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="4">
                    ความถี่ <span class="red--text">*</span>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-select v-model="data.frequency" :rules="[rules.required]" :items="frequentList" dense hide-details item-text="name" item-value="id" outlined
                              placeholder="ระบุ"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="4">
                    ตั้งแต่
                  </v-col>
                  <v-col cols="12" sm="8">
                    <my-date-picker v-model="data.datefrom"></my-date-picker>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="4">
                    ถึง
                  </v-col>
                  <v-col cols="12" sm="8">
                    <my-date-picker v-model="data.dateto" :min="data.datefrom"></my-date-picker>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="labelColumnClass" cols="12" sm="4">
                    สถิติ
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-select v-model="data.stat_type" :items="stat" chips deletable-chips dense hide-details item-text="name" item-value="id"
                              multiple outlined placeholder="ระบุ" small-chips></v-select>
                  </v-col>
                </v-row>
  <!--              <v-row>-->
  <!--                <v-col class="justify-end d-flex flex-wrap" cols="12">-->
  <!--                  <v-btn class="px-2" color="white" elevation="0" @click="exportPreview">-->
  <!--                    <v-icon class="pr-2">-->
  <!--                      mdi-file-search-->
  <!--                    </v-icon>-->
  <!--                    Preview-->
  <!--                  </v-btn>-->
  <!--                  <v-btn class="px-2" color="white" elevation="0" style="color: #f10000;" @click="exportPdf">-->
  <!--                    <v-icon class="pr-2">-->
  <!--                      mdi-file-pdf-->
  <!--                    </v-icon>-->
  <!--                    Download-->
  <!--                  </v-btn>-->
  <!--                  <v-btn class="px-2" color="white" elevation="0" style="color: #1D6F42;" @click="exportExcel">-->
  <!--                    <v-icon class="pr-2">-->
  <!--                      mdi-file-excel-->
  <!--                    </v-icon>-->
  <!--                    Download-->
  <!--                  </v-btn>-->
  <!--                </v-col>-->
  <!--              </v-row>-->
                <v-row>
                  <v-col class="justify-end d-flex flex-wrap" cols="12">
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <div class="d-flex justify-end" style="width: 100%">
          <v-btn elevation="0" @click="isOpenDialog=false">
            ปิด
          </v-btn>
          <v-btn class="ml-3" color="search-item white--text" elevation="0" @click="confirm">
            ส่งออก
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MyDatePicker from '../myDatePicker'
import { mapState } from 'vuex'
import projectConstant from '../../assets/js/projectConstant'
import utils from '../../assets/js/utils'

export default {
  name: 'pollutionSearch',
  components: { MyDatePicker },
  props: {
    isShow: Boolean,
    value: Object,
    measurementList: Array
  },
  mounted () {
    this.$store.dispatch('ref/getFactoryType')
    this.$store.dispatch('ref/getColony')
    this.$store.dispatch('ref/getZone')
    this.$store.dispatch('ref/getMeasurementType')
    this.$store.dispatch('ref/getStackFuelRef')
    this.$store.dispatch('getRegion')
    this.$store.dispatch('getProvince')
    this.$store.dispatch('ref/getParameterList')
      .then(data => { this.parameter = data })
  },
  data () {
    return {
      isOpenDialog: false,
      labelColumnClass: 'd-flex align-center justify-sm-end text-sm-right pb-0 pb-sm-3',
      district: [],
      parameter: [],
      frequentList: [
        {
          id: 'hourly',
          name: 'รายชั่วโมง'
        },
        {
          id: 'daily',
          name: 'รายวัน'
        },
        {
          id: 'monthly',
          name: 'รายเดือน'
        },
        {
          id: 'annual',
          name: 'รายปี'
        }
      ],
      stat: [
        { id: 'mean', name: 'ค่าเฉลี่ย' },
        { id: 'min', name: 'ค่าต่ำสุด' },
        { id: 'max', name: 'ค่าสูงสุด' }
      ],
      data: {
        factory: '',
        business: '',
        type_factory: 0,
        region: 0,
        province: 0,
        district: 0,
        colony: null, // type int
        zone: 0,
        brand: '',
        meas_type: null, // type int
        parameter: [],
        stack_fuel: 0,
        frequency: '',
        datefrom: '',
        dateto: '',
        stat_type: []
      },
      rules: projectConstant.rules
    }
  },
  methods: {
    exportPreview () {
      const url = 'https://poms.diw.go.th/jasperserver/rest_v2/reports/Reports/rpt_measurement.html'
      window.open(url, 'Download')
    },
    exportPdf () {
      const url = 'https://poms.diw.go.th/jasperserver/rest_v2/reports/Reports/rpt_measurement.pdf'
      window.open(url, 'Download')
    },
    exportExcel () {
      const url = 'https://poms.diw.go.th/jasperserver/rest_v2/reports/Reports/rpt_measurement.xlsx'
      window.open(url, 'Download')
    },
    buildPayload () {
      const payload = {}
      Object.keys(this.data).forEach(
        key => {
          if (key === 'parameter') {
            // remove -1 from data payload
            payload[key] = this.data[key].filter(item => item !== -1)
            return
          }
          if (key === 'colony' && this.data[key] === 0) {
            payload[key] = this.data[key]
            return
          }
          if (Array.isArray(this.data[key]) && this.data[key].length <= 0) {
            return
          }
          if (this.data[key]) {
            payload[key] = this.data[key]
          }
        }
      )
      payload.uuid = this.uuid
      return payload
    },
    confirm () {
      const isFormValid = this.$refs.searchForm.validate()
      if (!isFormValid) return false
      const payload = this.buildPayload()
      const query = new URLSearchParams(payload).toString()
      const url = process.env.VUE_APP_WS_BASE + '/export/report/?' + query
      if (utils.getDeviceType() === 3) {
        window.open(url, 'Download')
      } else {
        window.longdoJsInterface.openUrl({ url: url, title: 'ส่งออกข้อมูล' })
          .then(
            (result) => {
              if (!result) console.warn('open url not success with unknown error')
            },
            (error) => {
              if (error.code !== 1) console.warn('open url not success with this code', error)
            }
          )
      }
    }
  },
  computed: {
    ...mapState('ref', ['factoryType', 'measurementType', 'stackFuelRef', 'colonyIndustry', 'zone']),
    ...mapState(['province', 'region']),
    ...mapState('user', ['uuid']),
    forSelectProvince () {
      if (this.data.region === '' || !this.data.region) {
        return this.province
      } else {
        return this.province.filter(item => item.id_parent === this.data.region)
      }
    },
    measurementTypeOptions () {
      return [{ id: null, name: 'ทั้งหมด' }, ...this.measurementType]
    },
    parameterOptions () {
      return [{ id: -1, name: 'ทั้งหมด' }, ...this.parameter]
    }
  },
  watch: {
    isShow (newVal, oldVal) {
      if (newVal !== oldVal || newVal === true) {
        this.isOpenDialog = newVal
      }
      this.filteredParameter = []
      if (this.$refs.searchForm) this.$refs.searchForm.reset()
    },
    isOpenDialog (newVal, oldVal) {
      if (newVal !== oldVal || newVal === false) {
        this.$emit('isShowSignal', newVal)
      }
    },
    async 'data.province' (to, from) {
      if (!to || to === '') {
        return undefined
      }
      this.district = await this.$store.dispatch('getDistrict', { id_parent: to })
    },
    async 'data.meas_type' (to, from) {
      if (!to || to === '') {
        this.parameter = await this.$store.dispatch('ref/getParameterList')
      } else {
        this.parameter = await this.$store.dispatch('ref/getParameterListByMeasurementType', { id_parent: to })
      }
    },
    'data.parameter' (to, from) {
      const totalLength = this.parameterOptions.length
      if (to.length === totalLength) { // prevent infinite loop
        return
      }
      if (from.includes(-1) && !to.includes(-1)) { // if user un-tick all
        this.data.parameter = []
      }
      if (to.includes(-1)) { // if user tick all
        this.data.parameter = this.parameterOptions.map(item => item.id)
      }
    }
  }
}
</script>

<style scoped>

</style>
