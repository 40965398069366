<template>
  <v-app>
    <v-system-bar v-if="isOffline" app>
      <div style="width: 100%; height: 100%; text-align: center;">
        ไม่พบการเชื่อมต่ออินเทอร์เน็ต
      </div>
    </v-system-bar>
    <v-navigation-drawer v-model="drawer" :clipped="false" app color="primary" temporary>
      <v-list color="white">
        <v-list-item class="d-flex align-center justify-center pt-2">
          <div class="mx-3">
            <img :src="logo2" alt="web-logo" style="width: auto; height: 45px;">
          </div>
          <div class="mx-3">
            <div class="POMS_header" style=""><strong>POMS</strong></div>
          </div>
        </v-list-item>
        <v-list-item class="my-3" style="min-height: 0;">
          <v-divider style="width: 60%"/>
        </v-list-item>
        <v-list-item class="justify-center" style="min-height: 0;">
          <span class="primary--text text--darken-1 POMS_en">
            Pollution Online Monitoring System
          </span>
        </v-list-item>
        <v-list-item class="justify-center" style="min-height: 0;">
          <span class="primary--text text--darken-1 POMS_th">
            ระบบเฝ้าระวังและเตือนภัยมลพิษระยะไกล
          </span>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item-group v-model="selectedItem" class="pt-4">
          <v-list-item v-for="(item, i) in items" :key="i" exact @click="goToRouteName(item.href)">
            <v-list-item-action v-if="item.icon">
              <v-icon color="white">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text" v-text="item.title"/>
              <router-link :to="{name: item.href}" class="a_forRightClick" @click.prevent></router-link>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item v-if="permission.includes('access menu advance export')" exact
                     @click="pollutionSearchDialog=true; drawer=false;">
          <v-list-item-action>
            <v-icon color="white">mdi-map-search</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text" v-text="'สืบค้นข้อมูลมลพิษ'"/>
          </v-list-item-content>
        </v-list-item>
<!--        <v-list-item v-if="permission.includes('access menu ereport')" exact>-->
<!--          <v-list-item-action>-->
<!--            <v-icon color="white">mdi-file-document</v-icon>-->
<!--          </v-list-item-action>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title class="white&#45;&#45;text" v-text="'E-report'"/>-->
<!--            <a class="a_forRightClick" href="http://cems.diw.go.th/diw_analysis" target="_blank"></a>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
      </v-list>
    </v-navigation-drawer>
    <v-app-bar v-if="$vuetify.breakpoint.smAndUp" :clipped-left="false" :flat="isFlatAppBar" app color="white" fixed>
      <div class="appButtonContainer primary d-flex align-center justify-center">
        <v-icon class="white--text" @click.stop="drawer=!drawer">
          mdi-menu
        </v-icon>
      </div>
      <div class="d-flex align-center justify-center ml-3">
        <v-img :src="logo2" class="iconContainer" max-width="80"></v-img>
      </div>
      <div class="POMS_container" style="line-height: 1.1; height: 100%;">
        <div class="POMS_header" style=""><strong>POMS</strong></div>
        <div class="primary--text text--darken-1 POMS_en">Pollution Online Monitoring System</div>
        <div class="primary--text text--darken-1 POMS_th">ระบบเฝ้าระวังและเตือนภัยมลพิษระยะไกล</div>
      </div>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"/>
      <v-text-field v-if="(!uuid && showSearch) || (showSearch && permission.includes('use basic search'))"
                    v-model="keyword" class="d-none d-sm-flex searchField" dense hide-details outlined
                    placeholder="ชื่อหรือเลขทะเบียนโรงงาน" prepend-inner-icon="mdi-magnify" rounded
                    @keyup.enter="doSearch()">
        <template v-if="permission.includes('use advance search')" v-slot:append-outer>
          <div class="grey--text mt-1 clickText" style="font-size: 14px; width: 70px;"
               @click="advanceSearchDialog=true">
            ค้นหาขั้นสูง
          </div>
        </template>
      </v-text-field>
      <v-spacer/>
      <div class="d-none appButtonContainer d-sm-flex align-center justify-center pr-3" style="width: auto;">
        <div v-show="!this.uuid">
          <v-menu offset-y open-on-hover origin="center center" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="primary--text" style="font-weight: 500;">
                เข้าสู่ระบบ
              </span>
            </template>
            <div class="white rounded-lg elevation-3 px-2">
              <div class="py-3 px-1 clickText" @click="loginDialog=true">
                เจ้าหน้าที่กรมโรงงานอุตสาหกรรม / ผู้ประกอบการ
              </div>
              <v-divider/>
              <div class="py-3 px-1 clickText">
                <a class="black--text" href="http://authen.industry.go.th/" style="text-decoration: none;">เจ้าหน้าที่สำนักงานอุตสาหกรรมจังหวัด</a>
              </div>
            </div>
          </v-menu>
        </div>
        <div v-show="this.uuid">
          <v-menu offset-y open-on-hover origin="center center" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="d-flex align-center pr-3">
                  <div class="d-flex align-center fill-height mr-2">
                    <v-icon v-if="!profile_picture" large>mdi-account-circle</v-icon>
                    <v-img v-else :src="profile_picture" height="60"></v-img>
                  </div>
                  <div style="line-height: 1.2;">
                    <span style="font-size: 15px; line-height: 1; color: #0c3c84; font-weight: 500;">
                      {{ full_name || email }}</span>
                    <br/>
                    <span style="font-size: 13px; line-height: 1; color: #cfba7d;">{{ position }}</span>
                  </div>
                </div>
              </div>
            </template>
            <div class="primary text-center pa-3">
              <v-icon v-if="!profile_picture" class="white--text" large>mdi-account-circle</v-icon>
              <v-img v-else :src="profile_picture" height="60"></v-img>
              <br/>
              <span class="white--text" style="font-size: 15px">{{ full_name || email }}</span>
              <br/>
              <span style="font-size: 13px; color: #cfba7d;">{{ position }}</span>
            </div>
            <div class="white rounded-b-lg pa-3 elevation-2">
              <v-btn :ripple="false" block color="grey lighten-2 search--text" elevation="0" @click="doLogout">
                ออกจากระบบ
                <v-progress-circular v-show="isLoggingOut" class="ml-3" indeterminate size="16"></v-progress-circular>
              </v-btn>
            </div>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
    <v-main style="height: 100%">
      <div style="position: relative; height: 100%; width: 100%;">
        <router-view ref="page" v-on:logoutSignal="doLogout()" v-on:openFilter="openFilterHandler"
                     v-on:openLogin="openLoginHandler" v-on:openMenu="openMenuHandler"/>
        <notification-list></notification-list>
      </div>
    </v-main>
    <v-footer v-if="$vuetify.breakpoint.smAndUp" app class="primary white--text justify-center"
              style="font-weight: 300; font-size: 14px;">
      <span style="letter-spacing: 1px;">2021 -- DIW-POMS</span>
    </v-footer>
    <advance-search-dialog :isShow="advanceSearchDialog" v-on:advanceSearchClick="doAdvanceSearch"
                           v-on:isShowSignal="updateAdvanceSearchDialog"/>
    <login-dialog :isShow="loginDialog" v-on:isShowSignal="updateLoginDialog"
                  v-on:successQuery="refreshList"></login-dialog>
    <pollution-search :isShow="pollutionSearchDialog" v-on:isShowSignal="updatePollutionDialog"></pollution-search>
  </v-app>
</template>

<script>
import AdvanceSearchDialog from '../components/dialog/AdvanceSearch'
import { mapState } from 'vuex'
import LoginDialog from '../components/dialog/LoginDialog'
import NotificationList from '../components/notificationList'
import PollutionSearch from '../components/dialog/pollutionSearch'
// import utils from '../assets/js/utils'

export default {
  name: 'defaultLayout',
  components: {
    PollutionSearch,
    NotificationList,
    LoginDialog,
    AdvanceSearchDialog
  },
  mounted () {
    this.computeLayout()
    this.isOffline = !window.longdoJsInterface.Util.isOnLine()
    this.intervalId = setInterval(
      () => {
        this.isOffline = !window.longdoJsInterface.Util.isOnLine()
      }, 30 * 1000 // run this command every 0.5 min
    )
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  },
  data () {
    return {
      isOffline: false,
      intervalId: null,
      keyword: '',
      drawer: false,
      selectedItem: 0,
      advanceSearchDialog: false,
      loginDialog: false,
      showSearch: true, // control search text-field on each page
      logo2: require('../assets/img/ic_logo_3.png'),
      isFlatAppBar: false, // control elevation/shadow on v-app-bar on each page,
      pollutionSearchDialog: false
    }
  },
  methods: {
    async goToRouteName (name) {
      if (name === this.$route.name || !name) {
        return false
      } else {
        await this.$router.push({ name: name })
      }
    },
    async refreshList () {
      if (this.$route.name === 'Sensor') {
        await this.$refs.page.clearResult()
        await this.$refs.page.get(this.keyword)
      }
    },
    async doLogout () {
      let isSuccess = true
      const payload = { uuid: this.uuid || '', device: this.deviceId || '' }
      this.$store.dispatch('removeDeviceFromBackend', payload).then(() => {}) // do not wait this line
      isSuccess = isSuccess && await this.$store.dispatch('user/doLogout')
      if (isSuccess) {
        this.drawer = false
        if (this.$route.name !== 'Sensor') {
          await this.$router.push({ name: 'Sensor' })
        }
        await this.refreshList()
      }
    },
    updateAdvanceSearchDialog (e) {
      if (e !== this.advanceSearchDialog) {
        this.advanceSearchDialog = e
      }
    },
    updatePollutionDialog (e) {
      if (e !== this.pollutionSearchDialog) {
        this.pollutionSearchDialog = e
      }
    },
    updateLoginDialog (e) {
      if (e !== this.loginDialog) {
        this.loginDialog = e
      }
    },
    async doSearch () {
      this.$refs.page.setKeyword(this.keyword)
      await this.$refs.page.clearResult()
      this.$refs.page.get(this.keyword)
    },
    async doAdvanceSearch (payload) {
      this.$refs.page.doAdvanceSearchSearch(payload, this.keyword)
    },
    openMenuHandler (e) {
      this.drawer = true
    },
    openFilterHandler (e) {
      this.advanceSearchDialog = true
    },
    openLoginHandler (e) {
      this.loginDialog = true
    },
    computeLayout () {
      switch (this.$route.name) {
        case 'Sensor':
          this.keyword = ''
          this.showSearch = true
          this.isFlatAppBar = false
          break
        case 'SecondSensor':
          this.keyword = ''
          this.showSearch = true
          this.isFlatAppBar = false
          break
        case 'HelpDeskComment':
          this.showSearch = false
          this.isFlatAppBar = false
          break
        case 'HelpDeskRequest':
          this.showSearch = false
          this.isFlatAppBar = false
          break
        case 'Cctv':
          this.showSearch = false
          this.isFlatAppBar = false
          break
        default:
          this.showSearch = false
          this.isFlatAppBar = true
          break
      }
      let index
      if (this.$route.name === 'SecondSensor') {
        index = 0
      } else {
        index = this.items.findIndex(item => item.href === this.$route.name)
      }
      this.selectedItem = index
    }
  },
  watch: {
    uuid () {
      this.computeLayout()
    },
    '$route' () {
      this.computeLayout()
    }
  },
  computed: {
    ...mapState('user', ['uuid', 'full_name', 'profile_picture', 'email', 'isLoggingOut', 'permission', 'position', 'roles']),
    ...mapState(['firebaseCloudMessageToken', 'deviceId']),
    items () {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
      function intersection (setA, setB) {
        const _intersection = new Set()
        for (const elem of setB) {
          if (setA.has(elem)) {
            _intersection.add(elem)
          }
        }
        return _intersection
      }

      function arrayToSet (array) {
        const set = new Set()
        array.forEach(value => { set.add(value) })
        return set
      }

      const allRoute = [
        {
          icon: 'mdi-map',
          title: 'หน้าหลัก',
          href: 'Sensor',
          require: false
        },
        {
          icon: 'mdi-chart-pie',
          title: 'สถิติ',
          href: 'Statistic',
          require: false
        },
        {
          icon: 'mdi-cog',
          title: 'จัดการข้อมูลพื้นฐาน',
          href: 'Setting',
          require: ['view factory info', 'view measurement info', 'view device info', 'view instrument info'] // require one of this array
        },
        {
          icon: 'mdi-bell-alert',
          title: 'การแจ้งเตือน',
          href: 'Notification',
          require: ['view alert notification']
        },
        {
          icon: 'mdi-comment-text-outline',
          title: 'ข้อเสนอแนะ',
          href: 'HelpDeskComment',
          require: false
        },
        {
          icon: 'mdi-frequently-asked-questions',
          title: 'แจ้งขอความช่วยเหลือ',
          href: 'HelpDeskRequest',
          require: ['send request to staff']
        },
        {
          icon: 'mdi-file-eye-outline',
          title: 'ข้อมูลทั่วไป',
          href: 'GeneralInfo',
          require: ['access menu advance export']
        },
        {
          icon: 'mdi-file-document',
          title: 'E-report',
          href: 'EReport',
          require: ['access menu ereport']
        },
        {
          icon: 'mdi-chart-pie',
          title: 'สถิติ E-report',
          href: 'StatisticEReport',
          require: ['access menu ereport']
        },
        {
          icon: 'mdi-cctv',
          title: 'CCTV',
          href: 'Cctv',
          require: ['access menu cctv']
        }
      ]
      const showAblePath = []
      allRoute.forEach(
        (item, index) => {
          if (item.href === 'HelpDeskComment') {
            if (!this.uuid || this.permission.includes('send comment to staff')) {
              showAblePath.push(item)
            }
            return
          }
          if (item.require === false) {
            showAblePath.push(item)
          } else {
            const requirePermission = arrayToSet(item.require)
            const myPermission = arrayToSet(this.permission)
            const permissionResult = intersection(requirePermission, myPermission)
            if (permissionResult.size > 0) {
              showAblePath.push(item)
            }
          }
        }
      )
      return showAblePath
    }
  }
}
</script>

<style scoped>

/* <editor-fold desc="pc search text field"> */
@media (min-width: 600px) and (max-width: 770px) {
  .searchField {
    width: 35%;
    max-width: 500px;
  }
}

@media (min-width: 770px) and (max-width: 960px) {
  .searchField {
    width: 35%;
    max-width: 500px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

@media (min-width: 960px) and (max-width: 1220px) {
  .searchField {
    width: calc(100% - 700px);
    max-width: 500px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1220px) {
  .searchField {
    width: calc(100% - 500px);
    max-width: 500px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

/* </editor-fold>*/

@media (max-width: 960px) {
  .POMS_header {
    font-size: 26px;
    font-weight: 900;
    letter-spacing: 2px;
    background: -webkit-linear-gradient(#095CA0, #110B79);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .iconContainer {
    /*display: none;*/
  }

  .POMS_container {
    /*margin-left: 0px;*/
    /*padding-top: 4px;*/
    display: none;
  }
}

@media (min-width: 961px) {
  .POMS_header {
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 2px;
    background: -webkit-linear-gradient(#095CA0, #110B79);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .POMS_container {
    margin-left: 12px;
    padding-top: 4px;
  }
}

.POMS_en {
  font-size: 10px;
  font-weight: 500;
  font-family: Prompt, sans-serif !important;
}

.POMS_th {
  font-size: 10px;
  font-weight: 500;
  font-family: Prompt, sans-serif !important;
}

.appButtonContainer {
  width: 64px;
  height: 100%;
}

::v-deep .v-toolbar__content, .v-toolbar__extension {
  padding: 0;
}

::v-deep .v-list-item-group .v-list-item--active {
  background: rgb(158, 137, 77);
  background: -moz-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9e894d", endColorstr="#b7a676", GradientType=1);
}

::v-deep .v-navigation-drawer {
  height: 100% !important;
}
</style>
