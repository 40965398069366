import projectConstant from './projectConstant'

const utils = {
  dateToThai (date) {
    if (typeof date === 'string') {
      if (date === '') {
        return ''
      }
      const d = date.split('-')
      const month = projectConstant.monthDict(parseInt(d[1]))
      return (d[2] || '') + ' ' + month + ' ' + (parseInt(d[0]) + 543)
    } else {
      return null
    }
  },
  calculateDistanceFromLatLon (lat1, lon1, lat2, lon2) {
    function degToRad (deg) {
      return deg * Math.PI / 180
    }

    const R = 6371
    const dLat1 = degToRad(lat1)
    const dLon1 = degToRad(lon1)
    const dLat2 = degToRad(lat2)
    const dLon2 = degToRad(lon2)
    const ddLon = dLon2 - dLon1
    const ddLat = dLat2 - dLat1

    const a = Math.sin(ddLat / 2) * Math.sin(ddLat / 2)
    const b = Math.cos(dLat1) * Math.cos(dLat2) * Math.sin(ddLon / 2) * Math.sin(ddLon / 2)
    const c = a + b
    const d = 2 * Math.atan2(Math.sqrt(c), Math.sqrt(1 - c))
    return R * d
  },
  createUniqueBrowserId () {
    const chr4 = () => {
      return Math.random().toString(16).slice(-4)
    }
    return `${chr4()}${chr4()}-${chr4()}-${chr4()}-${chr4()}-${chr4()}${chr4()}${chr4()}`
  },
  getDeviceType () {
    const isAndroid = window.longdoJsInterface.Util.isAndroidNativeApp()
    const isIos = window.longdoJsInterface.Util.isIosNativeApp()
    if (isIos) {
      return 1
    } else if (isAndroid) {
      return 2
    } else {
      return 3
    }
  },
  numberWithCommas (x) {
    const s = x.toString()
    const a = s.split('.')
    let res = ''
    res += Number(a[0]).toLocaleString()
    if (a.length > 1) {
      res += `.${a[1]}`
    }
    return res
  },
  getFileSizeInMb (file) {
    if (file.size) {
      return file.size / (1024 * 1024) // from byte to mega byte
    } else {
      console.warn(file, 'is not sizeable')
      return 0.0
    }
  },
  payloadToFormData (payload) {
    const formData = new FormData()
    Object.keys(payload).forEach(key => {
      const data = payload[key]
      if (Array.isArray(data)) {
        data.forEach(
          item => {
            formData.append(`${key}[]`, item)
          }
        )
      } else {
        formData.append(key, payload[key])
      }
    })
    return formData
  }
}

export default utils
