import Vue from 'vue'
import VueRouter from 'vue-router'
import DefaultLayout from '../layouts/default.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'Sensor',
        component: () => import(/* webpackChunkName: "sensor" */ '../views/Sensor'),
        meta: {
          keepAlive: true
        }
      },
      {
        // use in default.vue :: computeLayout()
        path: '/sensor',
        name: 'SecondSensor',
        component: () => import(/* webpackChunkName: "sensor" */ '../views/Sensor'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/statistic',
        name: 'Statistic',
        component: () => import(/* webpackChunkName: "statistic" */ '../views/Statistic.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/setting',
        name: 'Setting',
        component: () => import(/* webpackChunkName: "setting" */ '../views/Setting.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/notification',
        name: 'Notification',
        component: () => import(/* webpackChunkName: "notification" */ '../views/Notification.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/helpDeskComment',
        name: 'HelpDeskComment',
        component: () => import(/* webpackChunkName: "help-desk-comment" */ '../views/helpDesk/Comment.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/helpDeskRequest',
        name: 'HelpDeskRequest',
        component: () => import(/* webpackChunkName: "help-desk-request" */ '../views/helpDesk/Request'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/generalInfo',
        name: 'GeneralInfo',
        component: () => import(/* webpackChunkName: "general-info" */ '../views/GeneralInfo'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/e-report',
        name: 'EReport',
        component: () => import(/* webpackChunkName: "e-report" */ '../views/EReport'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/statistic-e-report',
        name: 'StatisticEReport',
        component: () => import(/* webpackChunkName: "e-report" */ '../views/StatisticEReport'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/cctv-camera',
        name: 'Cctv',
        component: () => import(/* webpackChunkName: "cctv" */ '../views/cctv'),
        meta: {
          keepAlive: false
        }
      }
    ]
  },
  {
    path: '/company',
    component: () => import(/* webpackChunkName: "company-layout" */ '../layouts/CompanyView.vue'),
    children: [
      {
        path: '/company',
        name: 'Company',
        component: () => import(/* webpackChunkName: "company" */ '../views/Company.vue'),
        meta: {
          keepAlive: false
        }
      }
    ],
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/view-report',
    component: () => import(/* webpackChunkName: "report-layout" */ '../layouts/ReportView'),
    children: [
      {
        path: '/view-report',
        name: 'ViewReport',
        component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue'),
        meta: {
          keepAlive: false
        }
      }
    ],
    meta: {
      keepAlive: false
    }
  }
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.VUE_APP_BASE_URL,
  routes
})

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
function intersection (setA, setB) {
  const _intersection = new Set()
  for (const elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem)
    }
  }
  return _intersection
}

function arrayToSet (array) {
  const set = new Set()
  array.forEach(value => { set.add(value) })
  return set
}

function getRequiredPermission (to) {
  switch (to.name) {
    case 'Company':
      return ['view factory details']
    case 'Setting':
      // require one of this array
      return ['view factory info', 'view measurement info', 'view device info', 'view instrument info']
    case 'Admin':
      return ['administer diw_factory configuration']
    case 'Notification':
      return ['view alert notification']
    case 'HelpDeskRequest':
      return ['send request to staff']
    case 'GeneralInfo':
      return ['access menu advance export']
    case 'EReport':
      return ['access menu ereport']
    case 'ViewReport':
      return ['access menu ereport']
    case 'StatisticEReport':
      return ['access menu ereport']
    default:
      return false // no permission required
  }
}

async function loginUsingSid (to) {
  const sid = to.query.sid || false
  if (!sid) {
    return false
  }
  await store.dispatch('user/doLoginUsingSid', { sid: sid })
}

async function customBeforeEnter (to) {
  await loginUsingSid(to)
}

router.beforeEach(
  async (to, from, next) => {
    // cast owned permission to set
    // cast required permission to set
    // if the result of 2 sets intersection is more than 0 -> user have permission
    await customBeforeEnter(to)
    const promise = store.dispatch('user/loadInternalStorage')
    const requiredPermission = getRequiredPermission(to)
    if (to.name === 'HelpDeskComment') {
      await promise
      const currentPermission = store.state.user.permission
      const uuid = store.state.user.uuid
      if (!uuid || currentPermission.includes('send comment to staff')) {
        next()
      } else {
        next({ name: 'Sensor' })
      }
      return
    }
    if (requiredPermission === false) {
      next()
    } else {
      const rpSet = arrayToSet(requiredPermission)
      await promise
      const currentPermission = store.state.user.permission
      const cpSet = arrayToSet(currentPermission)
      const permissionResult = intersection(rpSet, cpSet)
      if (permissionResult.size > 0) {
        next()
      } else {
        next({ name: 'Sensor' })
      }
    }
  }
)

export default router
