import utils from '../../assets/js/utils'
import api from '../../assets/js/api'

const sensor = {
  namespaced: true,
  state: {
    sensor: {},
    hasBeenInternalLoad: false,
    hasBeenInternalLoadGraph: false,
    graphData: {},
    parameterList: []
  },
  getters: {},
  actions: {
    async getSensorData ({ commit, state, dispatch }, payload) {
      if (!state.hasBeenInternalLoad) {
        await dispatch('loadSensorData')
      }
      // sensor.factoryId.sensorType.date
      if (!payload.clear && state.sensor[payload.id] &&
        state.sensor[payload.id][payload.type] &&
        state.sensor[payload.id][payload.type][payload.date] &&
        state.sensor[payload.id][payload.type][payload.date][payload.uuid]) {
        return state.sensor[payload.id][payload.type][payload.date][payload.uuid]
      } else if (window.longdoJsInterface.Util.isOnLine() === true) {
        const factoryId = payload.id
        const formData = utils.payloadToFormData({ uuid: payload.uuid, date: payload.date, type: payload.type })
        const url = process.env.VUE_APP_WS_BASE + '/get/measurement-list/' + factoryId
        const response = await api.post({ url: url, data: formData })
        const data = response.data
        const ret = data.data
        // if date is not provide --> not save
        if (payload.date) {
          if (!state.sensor[payload.id]) {
            state.sensor[payload.id] = {}
          }
          if (!state.sensor[payload.id][payload.type]) {
            state.sensor[payload.id][payload.type] = {}
          }
          if (!state.sensor[payload.id][payload.type][payload.date]) {
            state.sensor[payload.id][payload.type][payload.date] = {}
          }
          state.sensor[payload.id][payload.type][payload.date][payload.uuid] = ret
          dispatch('saveSensorData')
        } else {
          // console.log('not save data because there are no date provided.')
        }
        return ret
      } else {
        return null
      }
    },
    async saveSensorData ({ commit, state, dispatch }, payload) {
      window.longdoJsInterface.addStorage({
        key: 'sensorData',
        value: JSON.stringify(state.sensor)
      }).then(
        () => {},
        (error) => {
          if (error.code !== 1) {
            console.warn('saveSensorData :: addStorage -->', error)
          }
        }
      )
    },
    async loadSensorData ({ commit, state, dispatch }, payload) {
      let sensor = {}
      try {
        const result = await window.longdoJsInterface.getStorage({
          key: 'sensorData'
        })
        sensor = JSON.parse(result)
      } catch (error) {
        if (error.code !== 1) {
          console.warn(error)
        }
      }
      state.hasBeenInternalLoad = true
      state.sensor = sensor
    },
    async getSensorGraph ({ commit, state, dispatch }, payload) {
      if (!state.hasBeenInternalLoadGraph) {
        await dispatch('loadGraphData')
      }
      // graphData[measurementId][parameterId][uuid][date]
      if (state.graphData[payload.measurementId] && state.graphData[payload.measurementId][payload.parameterId] &&
        state.graphData[payload.measurementId][payload.parameterId][payload.uuid] &&
        state.graphData[payload.measurementId][payload.parameterId][payload.uuid][payload.date]) {
        return state.graphData[payload.measurementId][payload.parameterId][payload.uuid][payload.date]
      } else if (window.longdoJsInterface.Util.isOnLine() === true) {
        const formData = utils.payloadToFormData({ uuid: payload.uuid || '', date: payload.date })
        const url = process.env.VUE_APP_WS_BASE + '/get/measurement-parameter/' + payload.measurementId + '/' + payload.parameterId
        const response = await api.post({ url: url, data: formData })
        const ret = response.data
        const data = ret.data
        if (payload.date) {
          if (!state.graphData[payload.measurementId]) {
            state.graphData[payload.measurementId] = {}
          }
          if (!state.graphData[payload.measurementId][payload.parameterId]) {
            state.graphData[payload.measurementId][payload.parameterId] = {}
          }
          if (!state.graphData[payload.measurementId][payload.parameterId][payload.uuid]) {
            state.graphData[payload.measurementId][payload.parameterId][payload.uuid] = {}
          }
          state.graphData[payload.measurementId][payload.parameterId][payload.uuid][payload.date] = data
        } else {
          console.log('not save data because there are no date provided.')
        }
        dispatch('saveGraphData')
        return data
      } else {
        return null
      }
    },
    async loadGraphData ({ commit, state, dispatch }, payload) {
      let graph = {}
      try {
        const result = await window.longdoJsInterface.getStorage({
          key: 'sensorGraph'
        })
        graph = JSON.parse(result)
      } catch (error) {
        if (error.code !== 1) {
          console.warn(error)
        }
      }
      state.hasBeenInternalLoadGraph = true
      state.graphData = graph
    },
    async saveGraphData ({ commit, state, dispatch }, payload) {
      window.longdoJsInterface.addStorage({
        key: 'sensorGraph',
        value: JSON.stringify(state.graphData)
      }).then(
        () => {},
        (error) => {
          if (error.code !== 1) {
            console.warn('saveSensorData :: addStorage -->', error)
          }
        }
      )
    },
    async setSensorDetail ({ commit, state, dispatch }, payload) {
      const data = payload
      const id = data.id
      delete data.id
      const params = data.params
      const visible = data.visible
      const channel = data.channel
      delete data.params
      delete data.visible
      delete data.channel
      // let query = new URLSearchParams(data)
      const formData = new FormData()
      Object.keys(data).forEach(key => formData.append(key, data[key]))
      if (params.length > 0) params.forEach((value, index) => { formData.append('params[]', value) })
      else formData.append('params', '')
      if (visible.length > 0) visible.forEach((value, index) => { formData.append('visible[]', value) })
      else formData.append('visible', '')
      if (channel.length > 0) channel.forEach((value, index) => { formData.append('channel[]', (value || '0')) })
      else formData.append('channel', '')
      // query = query.toString()
      const url = process.env.VUE_APP_WS_BASE + '/set/manage/measurement/' + id
      const response = await api.post({ url: url, data: formData })
      const res = response.data
      if (res.code === 'SUCCESS') {
        return true
      } else {
        console.warn(res)
        return res.message
      }
    },
    async deleteSensor ({ commit, state, dispatch }, payload) {
      const id = payload.id
      const formData = utils.payloadToFormData({ uuid: payload.uuid || '' })
      const url = process.env.VUE_APP_WS_BASE + '/del/manage/measurement/' + id
      const response = await api.post({ url: url, data: formData })
      const res = response.data
      if (res.code === 'SUCCESS') {
        return true
      } else {
        console.warn(res)
        return false
      }
    }
  },
  mutations: {}
}

export default sensor
