import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import dashboard from '../store/modules/dashboard'
import company from '../store/modules/company'
import user from '../store/modules/user'
import sensor from './modules/sensor'
import manage from './modules/manage'
import notification from './modules/notification'
import ref from './modules/ref'
import statistic from './modules/statistic'
import helpDesk from './modules/helpDesk'
import eReport from './modules/e-report'

import utils from '../assets/js/utils'
import api from '../assets/js/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mobileDetailHeight: 0, // Smoothly div height when route change.
    mobileDetailTop: 0,
    firebaseCloudMessageToken: false,
    deviceId: false,
    hasBeenLoadRegion: false,
    region: [],
    hasBeenLoadProvince: false,
    province: [],
    district: [],
    keepAliveResetSignal: true
  },
  mutations: {
    UPDATE_DETAIL_HEIGHT (state, value) {
      state.mobileDetailHeight = value
    },
    UPDATE_DETAIL_TOP (state, value) {
      state.mobileDetailTop = value
    },
    UPDATE_FCM_TOKEN (state, value) {
      state.firebaseCloudMessageToken = value
    },
    UPDATE_KEEP_ALIVE_SIGNAL (state, value) {
      state.keepAliveResetSignal = value
    }
  },
  actions: {
    async reverseGeocoding ({ state, commit, dispatch }, payload) {
      const longdoApiKey = process.env.VUE_APP_LONGDO_MAP_API_KEY
      const data = { ...payload, key: longdoApiKey }
      const query = new URLSearchParams(data).toString()
      const url = `${process.env.VUE_APP_LONGDO_BASE_PATH}/services/address?` + query
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      if (response.status === 200) {
        const address = response.data
        return address
      } else {
        console.warn(response)
        return {}
      }
    },
    async loadDeviceId ({ state, commit, dispatch }, payload) {
      const deviceType = utils.getDeviceType()
      if (deviceType === 3) {
        let webDeviceId = ''
        webDeviceId = localStorage.getItem('deviceId')
        if (!webDeviceId) {
          webDeviceId = utils.createUniqueBrowserId()
          localStorage.setItem('deviceId', webDeviceId)
        }
        state.deviceId = webDeviceId
      } else if (deviceType === 1 || deviceType === 2) {
        await window.longdoJsInterface.getUniqueDeviceId()
          .then(
            (id) => {
              state.deviceId = id
            },
            (e) => {
              console.warn(e)
            }
          )
      }
    },
    async saveDeviceToBackend ({ state, commit, dispatch }, payload) {
      payload.device = payload.device || state.deviceId || ''
      payload.token = payload.token || state.firebaseCloudMessageToken || ''
      if (payload.device && payload.token && payload.platform) {
        // const query = new URLSearchParams(payload).toString()
        const formData = new FormData()
        Object.keys(payload).forEach(key => formData.append(key, payload[key]))
        const url = process.env.VUE_APP_WS_BASE + '/set/device-fcm-token'
        const response = await api.post({ url: url, data: formData })
        const res = response.data
        if (res.code === 'SUCCESS') {
          return true
        } else {
          console.warn(res)
          return false
        }
      } else {
        console.warn('require key = [device, token, platform] to save', payload)
        return false
      }
    },
    async removeDeviceFromBackend ({ state, commit, dispatch }, payload) {
      if (!payload.device) {
        console.warn('require key = device', payload)
        return false
      }
      // const query = new URLSearchParams(payload).toString()
      const formData = new FormData()
      Object.keys(payload).forEach(key => formData.append(key, payload[key]))
      const url = process.env.VUE_APP_WS_BASE + '/del/device-fcm-token'
      const response = await api.post({ url: url, data: formData })
      const res = response.data
      if (res.code === 'SUCCESS') {
        return true
      } else {
        console.warn(res)
        return false
      }
    },
    async mapSuggest ({ state, commit, dispatch }, payload) {
      // require keyword
      const longdoApiKey = process.env.VUE_APP_LONGDO_MAP_API_KEY
      const data = { ...payload, key: longdoApiKey }
      const query = new URLSearchParams(data).toString()
      const url = `${process.env.VUE_APP_LONGDO_REST_PATH}/suggest?` + query
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      if (response.status === 200) {
        const data = response.data
        return data
      } else {
        console.warn(response)
        return {}
      }
    },
    async mapSearch ({ state, commit, dispatch }, payload) {
      // require keyword
      const longdoApiKey = process.env.VUE_APP_LONGDO_MAP_API_KEY
      const data = { ...payload, key: longdoApiKey }
      const query = new URLSearchParams(data).toString()
      const url = `${process.env.VUE_APP_LONGDO_REST_PATH}/search?` + query
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      if (response.status === 200) {
        const data = response.data
        return data
      } else {
        console.warn(response)
        return {}
      }
    },
    async loadRegion ({ state, commit, dispatch }, payload) {
      if (!state.hasBeenLoadRegion && !window.longdoJsInterface.Util.isOnLine()) {
        try {
          const result = await window.longdoJsInterface.getStorage({
            key: 'ref-region'
          })
          state.region = JSON.parse(result) || []
        } catch (e) {
          if (e.code !== 1) {
            console.warn('load-region -->', e)
          }
        }
        state.hasBeenLoadRegion = true
      }
    },
    saveRegion ({ state, commit, dispatch }, payload) {
      window.longdoJsInterface.addStorage({
        key: 'ref-region',
        value: JSON.stringify(state.region)
      }).then(
        () => {},
        (error) => {
          if (error.code !== 1) {
            console.warn('getRegion :: addStorage -->', error)
          }
        }
      )
    },
    async getRegion ({ state, commit, dispatch }, payload) {
      await dispatch('loadRegion')
      if (state.region.length < 1) {
        const url = process.env.VUE_APP_WS_BASE + '/get/ref/region'
        const response = await axios({
          url: url,
          method: 'GET',
          validateStatus: function (status) {
            return true
          }
        })
        const data = response.data
        if (data.code === 'SUCCESS') {
          state.region = data.data
          dispatch('saveRegion')
          return data.data
        } else {
          console.warn(data)
          return []
        }
      } else {
        return state.region
      }
    },
    async loadProvince ({ state, commit, dispatch }, payload) {
      if (!state.hasBeenLoadProvince && !window.longdoJsInterface.Util.isOnLine()) {
        try {
          const result = await window.longdoJsInterface.getStorage({
            key: 'ref-province'
          })
          state.province = JSON.parse(result) || []
        } catch (e) {
          if (e.code !== 1) {
            console.warn('load-province -->', e)
          }
        }
        state.hasBeenLoadProvince = true
      }
    },
    saveProvince ({ state, commit, dispatch }, payload) {
      window.longdoJsInterface.addStorage({
        key: 'ref-province',
        value: JSON.stringify(state.province)
      }).then(
        () => {},
        (error) => {
          if (error.code !== 1) {
            console.warn('getProvince :: addStorage -->', error)
          }
        }
      )
    },
    async getProvince ({ state, commit, dispatch }, payload) {
      await dispatch('loadProvince')
      if (state.province.length < 1) {
        const url = process.env.VUE_APP_WS_BASE + '/get/ref/province'
        const response = await axios({
          url: url,
          method: 'GET',
          validateStatus: function (status) {
            return true
          }
        })
        const data = response.data
        if (data.code === 'SUCCESS') {
          state.province = data.data
          dispatch('saveProvince')
          return data.data
        } else {
          console.warn(data)
          return []
        }
      } else {
        return state.province
      }
    },
    async getDistrict ({ state, commit, dispatch }, payload) {
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/district?' + 'id_parent=' + payload.id_parent
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        state.district = data.data
        state.district.splice(0, 0)
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async getSubDistrict ({ state, commit, dispatch }, payload) {
      const url = process.env.VUE_APP_WS_BASE + '/get/ref/subdistrict?' + 'id_parent=' + payload.id_parent
      const response = await axios({
        url: url,
        method: 'GET',
        validateStatus: function (status) {
          return true
        }
      })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    async exportPollutionSearchCsv ({ state, commit, dispatch }, payload) {
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/export/report'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return false
      }
    },
    async getCctv ({ state, commit, dispatch }, payload) {
      const url = process.env.VUE_APP_WS_BASE + '/get/cctv-list'
      const formData = new FormData()
      Object.keys(payload).forEach(key => formData.append(key, payload[key]))
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return []
      }
    },
    refreshKeepAlive ({ commit }) {
      // remount keep-alive in app.vue
      commit('UPDATE_KEEP_ALIVE_SIGNAL', false)
      Vue.nextTick(() => {
        Vue.nextTick(() => {
          commit('UPDATE_KEEP_ALIVE_SIGNAL', true)
        })
      })
    }
  },
  modules: {
    dashboard,
    company,
    user,
    sensor,
    manage,
    notification,
    ref,
    statistic,
    helpDesk,
    eReport
  }
})
