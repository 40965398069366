import utils from '../../assets/js/utils'
import api from '../../assets/js/api'

const helpDesk = {
  namespaced: true,
  actions: {
    async sendComment ({ commit, state, dispatch }, payload) {
      if (!payload.reporter || !payload.tel || !payload.email || !payload.title || !payload.body) {
        console.log('require key [reporter, tel, email, title, body] get', payload)
        return false
      }
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/set/helpdesk-comment'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return false
      }
    },
    async sendRequest ({ commit, state, dispatch }, payload) {
      if (!payload.uuid || !payload.request_type || !payload.measurement_type || !payload.title || !payload.body) {
        console.log('require key [uuid, request_type, measurement_type, title, body] get', payload)
        return false
      }
      const formData = utils.payloadToFormData(payload)
      const url = process.env.VUE_APP_WS_BASE + '/set/helpdesk-request'
      const response = await api.post({ url: url, data: formData })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return true
      } else {
        console.warn(data)
        return false
      }
    }
  }
}

export default helpDesk
