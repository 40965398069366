const projectConstant = {
  monthDict (val) {
    const monthDict = {
      1: 'ม.ค.',
      2: 'ก.พ.',
      3: 'มี.ค.',
      4: 'เม.ย.',
      5: 'พ.ค.',
      6: 'มิ.ย.',
      7: 'ก.ค.',
      8: 'ส.ค.',
      9: 'ก.ย.',
      10: 'ต.ค.',
      11: 'พ.ย.',
      12: 'ธ.ค.'
    }
    return monthDict[val]
  },
  rules: {
    required (data) {
      if (!data && data !== 0) {
        return 'ต้องการ'
      } else {
        return true
      }
    },
    email (x) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(x).toLowerCase()) || 'อีเมลไม่ถูกต้อง'
    },
    requireOne (x) {
      if (Array.isArray(x) && x.length > 0) {
        return true
      } else {
        return 'ต้องการ'
      }
    }
  }
}

export default projectConstant
